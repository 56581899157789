import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { withRouter, Link, useHistory } from "react-router-dom";

//i18n

import { url } from "store/constant/base_url";

import { withTranslation } from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

import { connect } from "react-redux";

const ProfileMenu = () => {
  const base_url = url;

  const image = localStorage.getItem("image");

  const history = useHistory();

  const logOutHandler = () => {
    localStorage.clear();
    history.push("/login");
  };
  return (
    <React.Fragment>
      <UncontrolledDropdown className="float-right d-flex justify-content-end align-self-center ">
        <DropdownToggle href="#" className="arrow-none" tag="i">
          <img
            className="rounded-circle header-profile-user"
            src={`${base_url}/user/fetch_profile_image/${image}`}
            style={{ cursor: "pointer" }}
            alt="Header Avatar"
          />{" "}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <Link to="/profile" className="text-muted font-weight-bold">
              <i className="bx bx-user font-size-16 align-middle ms-1"></i>{" "}
              Profile
            </Link>
            <div className="dropdown-divider"></div>
            <span onClick={logOutHandler}>
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
              <span>Logout</span>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
