import axios from "axios";
import { url } from "store/constant/base_url";


const baseUrl = url

const loginNewUser = ( data) => {
    var url = `${baseUrl}/login`
  return axios
    .post(url, data, {})
    .then((response) => {


        return response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Invalid Login Credentials";
      }
    });
};



const addNewProject = (data) => {
  return axios({
      method: 'post',
      url: baseUrl+'/project',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};

const updatePasswordApi = (data) => {
  return axios({
      method: 'PUT',
      url: baseUrl+'/user/update_password',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};






const onUpdateNewStatus = (data) => {
  return axios({
      method: 'PUT',
      url: baseUrl+'/ticket/update_ticket_field',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};


const onUpdateNewStatusKanban = (data) => {
  return axios({
      method: 'PUT',
      url: baseUrl+'/ticket/update_ticket_field',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};









const addNewLinkHandler = (data) => {
  return axios({
      method: 'post',
      url: baseUrl+'/ticket/link_ticket',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};




const addNewComment = (data) => {
  return axios({
      method: 'post',
      url: baseUrl+'/ticket_comment',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};





const addNewTicket = (data) => {
  return axios({
      method: 'post',
      url: baseUrl+'/ticket',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};


const editTicket = (data) => {
  return axios({
      method: 'PUT',
      url: baseUrl+'/ticket',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};


const onFetchEvent = () => {
  return axios({
      method: 'GET',
      url: baseUrl+'/calendar_event',

      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data.data;

    
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};

const deleteEventApi = (id,member) => {
  return axios({
      method: 'DELETE',
      url: baseUrl+'/calendar_event/'+id+ "/"+member,

      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data.data;

    
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};






const editCommentHandlerApi = (data) => {
  return axios({
      method: 'PUT',
      url: baseUrl+'/ticket_comment',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};





const addNewAttachment = (data) => {
  return axios({
      method: 'post',
      url: baseUrl+'/project_attachment',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};

const addNewAttachmentTicket = (data) => {
  return axios({
      method: 'PUT',
      url: baseUrl+'/ticket_attachment',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};





const deleteAttachment = (id,value) => {
  return axios({
      method: 'DELETE',
      url: baseUrl+'/project_attachment/'+id+"/"+value,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};

const deleteAttachmentTicket = (id,value) => {
  return axios({
      method: 'DELETE',
      url: baseUrl+'/ticket_attachment/'+id+"/"+value,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};


const updateProject = (data) => {
  return axios({
      method: 'PUT',
      url: baseUrl+'/project',
      data: data,
      headers: {'Content-Type': 'multipart/form-data', "Authorization" : `Bearer  ${localStorage.getItem('token')}`}
      })

    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;

      throw response.data;
    })
    .catch((err) => {
      if (err.response.data.status == "error") {
        throw "Oops Something went wrong";
      }
    });
};







const fetchProjectById = (id) => {
  var projectUrl = `${baseUrl}`+'/project'
  var url = `${projectUrl}/${id}`
  return axios({
     method: 'GET',
    url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};

const fetchUserApi = (id) => {
  var url = `${baseUrl}`+'/user/get_user_profile_info/'+id

  return axios({
     method: 'GET',
    url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};






const fetchGraphDataDaily = (id) => {
  var graphUrl = `${baseUrl}`+'/project_graph/tickets_overview'
  var url = `${graphUrl}/${id}`
  return axios({
    method: 'GET',
    url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};

const fetchGraphDataWeekly = (id) => {
  var graphUrl = `${baseUrl}`+'/performance_graph/tickets_weekly_graph'
  var url = `${graphUrl}/${id}`
  return axios({
    method: 'GET',
    url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};

const fetchTicketOwnerGraph = (id) => {

  var url = `${baseUrl}/performance_graph/tickets_owners_graph/${id}/status`
  return axios({
    method: 'GET',
    url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};








const onFetchComment = (id) => {
  var projectUrl = `${baseUrl}`+'/ticket_comment'
  var url = `${projectUrl}/${id}`
  return axios({
     method: 'GET',
    url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
    
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};


const onFetchSingleEvent = (id) => {
  var projectUrl = `${baseUrl}`+'/calendar_event'
  var url = `${projectUrl}/${id}`
  return axios({
     method: 'GET',
     url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
    
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};


const fetchDashboardDataApi = () => {
  var dashboardUrl = `${baseUrl}`+'/user/dashboard_info'
  var url = `${dashboardUrl}`
  return axios({
     method: 'GET',
     url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
    
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};



const updateProfileApi = (data) => {
  var dashboardUrl = `${baseUrl}`+'/user'
  var url = `${dashboardUrl}`
  return axios({
     method: 'PUT',
     url: url,
     data: data,
     headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
    
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};






const addNewEvent = (data) => {
  var calendartUrl = `${baseUrl}/calendar_event`
  return axios({
     method: 'POST',
    url: calendartUrl,
    data: data,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};

const editEventApi = (data) => {
  var calendartUrl = `${baseUrl}/calendar_event`
  return axios({
     method: 'PUT',
    url: calendartUrl,
    data: data,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};









const fetchTicketById = (id) => {
  var projectUrl = `${baseUrl}`+'/ticket'
  var url = `${projectUrl}/${id}`
  return axios({
    method: 'GET',
    url: url,
    // params:{},
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};











const fetchAllUsers = (id) => {
  var url = `${baseUrl}`+'/user/get_all_users'

  return axios({
     method: 'GET',
    url: url,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {

    console.log(response.message)
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};


const fetchAllCustomers = () => {
  var url = `${baseUrl}`+'/user/get_all_customers'

  return axios({
     method: 'GET',
    url: url,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {

    console.log(response.message)
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};





const fetchAllSubTickets = (id) => {
  var url = `${baseUrl}/ticket/all_non_parent_tickets/${id}`

  return axios({
     method: 'GET',
    url: url,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};


const fetchTicketByProject = (id) => {
  return axios({
    method: 'GET',
    url: baseUrl + '/ticket/all/'+id,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};

const fetchAllProject = () => {
  var url = `${baseUrl}/project/all`
  return axios({
    method: 'get',
    url: url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
      return response.data.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};


const fetchAllIcons = (var1,var2) => {
  var url = `${baseUrl}/ticket/get_icon/${var2}/${var1}`
  return axios({
    method: 'get',
    url: url,
    headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
      return response.data;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};



const deleteProjectById = (id) => {
  var projectUrl = `${baseUrl}`+'/project'
  var url = `${projectUrl}/${id}`
  return axios({
    method: 'DELETE',
    url: url,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.message;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};

const deleteCommentByIndexApi = (ticketId,index) => {
  var commentUrl = `${baseUrl}`+'/ticket_comment'
  var url = `${commentUrl}/${ticketId}/${index}`
  return axios({
    method: 'DELETE',
    url: url,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.message;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};

const delinkTicketHandlerApi = (ticketId,subTicketId) => {
  var subTicketUrl = `${baseUrl}`+'/ticket/delink_ticket'
  var url = `${subTicketUrl}/${ticketId}/${subTicketId}`
  return axios({
    method: 'DELETE',
    url: url,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.message;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};









const deleteTicketById = (id) => {
  var projectUrl = `${baseUrl}`+'/ticket'
  var url = `${projectUrl}/${id}`
  return axios({
    method: 'DELETE',
    url: url,
    headers: {"Authorization" : `Bearer  ${localStorage.getItem('token')}`}
    })
  .then((response) => {
    if (response.status >= 200 || response.status <= 299)
   
       return response.data.message;

  })
  .catch((err) => {
    if (err.response.data.status == "error") {
      throw "Oops Something went wrong";
    }
  });

};






//Add comment







export {addNewComment, loginNewUser,addNewEvent,updateProfileApi,updatePasswordApi,fetchDashboardDataApi,deleteEventApi,onFetchSingleEvent,fetchAllSubTickets,onFetchEvent,editEventApi,onUpdateNewStatusKanban,fetchGraphDataWeekly,fetchTicketOwnerGraph,fetchGraphDataDaily,onUpdateNewStatus, addNewProject,onFetchComment,delinkTicketHandlerApi,fetchAllIcons,addNewLinkHandler, fetchAllProject,deleteProjectById,deleteCommentByIndexApi, editCommentHandlerApi,addNewTicket,fetchTicketById, deleteAttachmentTicket,addNewAttachmentTicket, fetchTicketByProject,editTicket,fetchAllUsers,fetchAllCustomers,deleteTicketById,fetchProjectById,fetchUserApi,updateProject,addNewAttachment,deleteAttachment};
