import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { addNewProject, FetchCustomer, FetchUser } from "../../store/projects/actions";
import CardProject from "./card-project";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
  Modal,
} from "reactstrap";
import { signUpSchema } from "./schemas";

import { useFormik } from "formik";
//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import makeAnimated from "react-select/animated";
const statuses = ["Pending", "In progress", "Completed"];
const initialValues = {
  title: "",
  short_description: "",
  start_date: null,
  end_date: null,
  status: "",
  project_code: "",
};

const Project = props => {
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signUpSchema,
      onSubmit: values => {
        onCreateProject(values)
      },
    });
  const history = props.history;

  const dispatch = useDispatch();
  const userList = useSelector(state => state.projects.users);
  const customerList = useSelector(state => state.projects.customer);


  userList.forEach(object => {
    object.label = object.first_name + " " + object.last_name;
    object.value = object.uid;
  });

  customerList.forEach(object => {
    object.label = object.first_name 
    object.value = object.uid;
  });


  const result = userList.map(
    ({ _id, phone, email, user_image, first_name, last_name, ...rest }) => ({
      ...rest,
    })
  );

  useEffect(() => {
    dispatch(FetchUser());
    dispatch(FetchCustomer());

    
  }, []);

  const animatedComponents = makeAnimated();

  const managerOptions = result;
  const teamMembers = result;

  const [attachments, setAttachments] = useState([]);
  const [detail, setDetail] = useState("");

  const [manager, setManager] = useState([]);
  const [member, setTeamMember] = useState([]);
  const [customer, setCustomer] = useState([]);

  const [logo, setLogo] = useState();

  // validation

  const [isManager, setIsManager] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isDetail, setIsDetail] = useState(false);

  const onLogoChange = e => {
    setLogo(e.target.files[0]);
  };

  const onManagerValue = e => {
    if (e) {
      setIsManager(false);
    } else {
      setIsManager(true);
    }
    setManager(e);
  };

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        // formattedSize: formatBytes(file.size),
      })
    );
    setAttachments(files);
  };

  const onMemberChange = e => {
    if (e) {
      setIsMember(false);
    } else {
      setIsMember(true);
    }
    setTeamMember(e);
  };

  const onCustomerChange = e => {
 

    console.log(e)


    setTeamMember(member => [...member, e]);


  };

  

  const onCreateProject = value => {
    let formData = new FormData();
    formData.append("title", value.title);
    formData.append("project_code", value.project_code);
    formData.append("short_description", value.short_description);
    formData.append("project_details", detail);
    formData.append("status", value.status);
    formData.append("start_date", value.start_date);
    formData.append("end_date", value.end_date);
    formData.append("manager", JSON.stringify(manager));
    formData.append("team_members", JSON.stringify(member));
    formData.append("logo", logo);


    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        formData.append("attachment", attachments[i]);
      }
    }

    var data = {
      formData: formData,
      history: history,
    };
    dispatch(addNewProject(data));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Project Title
                      </Label>
                      <Col lg="10">
                        <Input
                          name="title"
                          className="form-control"
                          placeholder="Enter Project Title..."
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.title && touched.title ? (
                          <small className="text-danger fs-6">
                            {errors.title}
                          </small>
                        ) : (
                          ""
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Project Code
                      </Label>
                      <Col lg="10">
                        <Input
                          name="project_code"
                          className="form-control"
                          placeholder="Enter Project Code..."
                          value={values.project_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.project_code && touched.project_code ? (
                          <small className="text-danger fs-6">
                            {errors.project_code}
                          </small>
                        ) : (
                          ""
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label className="col-form-label col-lg-2">Logo</label>

                      <Col lg="10">
                        <Input
                          name="logo"
                          onChange={onLogoChange}
                          type="file"
                          placeholder=""
                          className="form-control form-control-lg"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Project Details
                      </Label>
                      <Col lg="10">
                        <CKEditor
                          editor={ClassicEditor}
                          data=""
                          onReady={editor => {
                            console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setDetail(data);
                          }}
                          onBlur={() => {
                            if (detail.length == 0) {
                              setIsDetail(true);
                            }
                          }}
                        />
                        {isDetail && detail.length == 0 ? (
                          <small className="text-danger fs-6">
                            Please fill the detail
                          </small>
                        ) : (
                          ""
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label className="col-md-2 col-form-label">Status</label>
                      <div className="col-md-10">
                        <select
                          className="form-control"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option hidden selected>
                            Select{" "}
                          </option>
                          {statuses.map((item, i) => (
                            <option key={i}>{item}</option>
                          ))}
                        </select>

                        {errors.status && touched.status ? (
                          <small className="text-danger fs-6">
                            {errors.status}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label className="col-md-2 col-form-label">
                        Short Description
                      </label>
                      <div className="col-md-10">
                        <textarea
                          className="form-control"
                          name="short_description"
                          value={values.short_description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></textarea>
                        {errors.short_description &&
                        touched.short_description ? (
                          <small className="text-danger fs-6">
                            {errors.short_description}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">
                        Project Date
                      </Label>
                      <Col lg="10">
                        <Row>
                          <Col md={6} className="pr-0">
                            <input
                              type="date"
                              className="form-control"
                              name="start_date"
                              value={values.start_date}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.start_date && touched.start_date ? (
                              <small className="text-danger fs-6">
                                Start date is required
                              </small>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={6} className="pl-0">
                            <Input
                              type="date"
                              className="form-control"
                              name="end_date"
                              value={values.end_date}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.end_date && touched.end_date ? (
                              <small className="text-danger fs-6">
                                End date is required
                              </small>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label className="col-form-label col-lg-2">Manager</label>
                      <Col lg="10">
                        <Select
                          onChange={onManagerValue}
                          // value={manager}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={managerOptions}
                          onBlur={() => {
                            if (manager.length == 0) {
                              setIsManager(true);
                            }
                          }}
                        />

                        {isManager && (
                          <small className="text-danger fs-6">
                            Manager is required
                          </small>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label className="col-form-label col-lg-2">
                        Team Members
                      </label>
                      <Col lg="10">
                        <Select
                          closeMenuOnSelect={false}
                          onChange={onMemberChange}
                          components={animatedComponents}
                          isMulti
                          options={teamMembers}
                          onBlur={() => {
                            if (member.length == 0) {
                              setIsMember(true);
                            }
                          }}
                        />

                        {isMember && (
                          <small className="text-danger fs-6">
                            Member is required
                          </small>
                        )}
                      </Col>
                    </FormGroup>


                    <FormGroup className="mb-4" row>
                      <label className="col-form-label col-lg-2">
                        Customers
                      </label>
                      <Col lg="10">
                        <Select
                          closeMenuOnSelect={false}
                          onChange={onCustomerChange}
                          components={animatedComponents}
                          
                          options={customerList}
                    
                        />

               
                      </Col>
                    </FormGroup>


                    <FormGroup className="mb-4" row>
                      <label className="col-form-label col-lg-2">
                        Attachement
                      </label>

                      <Col lg="10">
                        <Dropzone
                          onDrop={acceptedFiles =>
                            handleAcceptedFiles(acceptedFiles)
                          }
                          onBlur={() => {
                            if (attachments.length == 0) {
                              setIsAttachment(true);
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {attachments.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>

                        {/* <Input
                          multiple
                          name="attachment"
                          onChange={onFileChange}
                          type="file"
                          placeholder=""
                          className="form-control form-control-lg"
                        /> */}
                      </Col>
                    </FormGroup>

                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={
                            values.title.length == 0 ||
                            values.short_description.length == 0 ||
                            !values.start_date ||
                            !values.end_date ||
                            values.status.length == 0 ||
                            values.project_code.length == 0 ||
                            detail.length == 0 ||
                            manager.length == 0 ||
                            member.length == 0
                          }
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*           
          <Row>
            <CardProject projects={projects} />
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

// const mapStateToProps = ({ projects }) => ({
//   projects: projects.projects,
// });

// const mapDispatchToProps = dispatch => ({
//   onGetProjects: () => dispatch(getProjects()),
//   onAddNewProject: project => dispatch(addNewProject(project)),
// });

// export default withRouter(
//   connect(mapStatetoProps, { AddProject, apiProjectError })(Project)
// );

export default Project;
