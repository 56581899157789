import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

//

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
import { useFormik } from "formik";
import { ProfileSchema, PasswordSchema } from "./ProfileSchema";
// actions
import {
  editProfile,
  fetchUserInformation,
  updateProfile,
} from "../../store/actions";
import Password from "./Password";
import { url } from "store/constant/base_url";
import { isEmpty } from "lodash";

const Profile = props => {
  useEffect(() => {
    dispatch(fetchUserInformation(uid));
  }, []);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [contact_number, setPhone] = useState("");

  const base_url = url;

  const userDetails = useSelector(state => state.projects.userDetail);

  const name = localStorage.getItem("first_name");
  const uid = localStorage.getItem("uid");

  const [image, setImage] = useState("");

  const onProfileChange = e => {
    setImage(e.target.files[0]);
  };

  const initialValues = {
    firstName: first_name,
    lastName: last_name,
    address: address,
    contactNumber: contact_number,
  };

  useEffect(() => {
    if (userDetails.length) {
      setFirstName(userDetails[0]["first_name"]);
      setLastName(userDetails[0]["last_name"]);
      setAddress(userDetails[0]["address"]);
      setPhone(userDetails[0]["phone"]);
    }
  }, [userDetails]);

  const dispatch = useDispatch();
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: ProfileSchema,
      onSubmit: values => {
        onUpdateProfile(values);
      },
    });

  const onUpdateProfile = value => {
    let formData = new FormData();
    formData.append("first_name", value.firstName);
    formData.append("last_name", value.lastName);
    formData.append("phone", value.contactNumber);
    formData.append("address", value.address);
    if (image) {
      formData.append("user_image", image);
    }

    var data = {
      formData: formData,
      history: history,
    };
    dispatch(updateProfile(data, uid));

    swal("Your details has been updated!", {
      icon: "success",
      timer:2000
    });

  };

  return (
    <React.Fragment>
      {userDetails.length ? (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            {/* <Breadcrumb title="Dashboard" breadcrumbItem="Profile" /> */}

            <div className="row mb-2">
              <div className="col-12 d-flex justify-content-end">
                <span>
                  <Link to={"/dashboard"}>Dashboard</Link>
                  <span className="text-muted">/Profile</span>
                </span>
              </div>
            </div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Media>
                      <div className="mr-3">
                        <img
                          src={`${base_url}/user/fetch_profile_image/${userDetails[0]["user_image"]}`}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <Media body className="align-self-center">
                        <div className="text-muted">
                          <h5>
                            {" "}
                            {userDetails[0]["first_name"]}{" "}
                            {userDetails[0]["last_name"]}{" "}
                          </h5>
                          <p className="mb-1">
                            {userDetails[0]["designation"]}{" "}
                          </p>
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Update Profile Information</h4>

            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormGroup
                    className="mb-4 d-flex justify-content-between"
                    row
                  >
                    <div className="col-md-6 ">
                      <label className="col col-form-label">First Name</label>

                      <input
                        name="firstName"
                        className="form-control"
                        placeholder="Enter Name..."
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.firstName && touched.firstName ? (
                        <small className="text-danger fs-6">
                          {errors.firstName}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 ">
                      <label className="col col-form-label">Last Name</label>

                      <input
                        name="lastName"
                        className="form-control"
                        placeholder="Enter Last Name..."
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.lastName && touched.lastName ? (
                        <small className="text-danger fs-6">
                          {errors.lastName}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 ">
                      <label className="col col-form-label">Profile</label>

                      <input
                        name="user_image"
                        className="form-control"
                        placeholder="Enter Last Name..."
                        type="file"
                        onChange={onProfileChange}
                      />
                    </div>

                    <div className="col-md-6 ">
                      <label className="col col-form-label">
                        Contact Number{" "}
                      </label>

                      <input
                        name="contactNumber"
                        className="form-control"
                        placeholder="Enter Contact Number..."
                        value={values.contactNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.contactNumber && touched.contactNumber ? (
                        <small className="text-danger fs-6">
                          {errors.contactNumber}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 ">
                      <label className="col col-form-label">Address</label>

                      <textarea
                        name="address"
                        className="form-control"
                        placeholder="Enter Address..."
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></textarea>

                      {errors.address && touched.address ? (
                        <small className="text-danger fs-6">
                          {errors.address}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </FormGroup>

                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Form>
              </CardBody>
            </Card>

            <h4 className="card-title mb-4">Update Password</h4>

            <Card>
              <CardBody>
                <Password />
              </CardBody>
            </Card>
          </Container>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Profile;
