import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Form,
  FormGroup,
  TabPane,
  Label,
  Button,
  ModalBody,
  Modal,
  Table,
} from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";

import classnames from "classnames";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import {
  addComment,
  deleteCommentByIndex,
  EditCommentHandler,
  fetchComment,
} from "store/actions";
import { useParams, Link } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";
import ActivityComp from "pages/Dashboard/ActivityComp";

const Tabs = props => {

  const params = useParams();
  // const commits = []
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [fetchCommentValue, setFetchCommentValue] = useState("");
  const commits = props.ticket.commits ? props.ticket.commits : [];

  const [index, setIndex] = useState();

  const editCommentIndex = (i, comment) => {
    setEditModal(true);
    setIndex(i);
    setFetchCommentValue(comment);
  };

  const commentList = useSelector(state => state.projects.ticketComments);

  console.log(commentList)


 useEffect(()=>{
  const fetchSortedDate = () => {

    return (
      commits.sort((x, y) => {
            return new Date(x.timestamp) > new Date(y.timestamp) ? 1 : -1
        })
    ).reverse()
}

fetchSortedDate()

 })

  const [comment, setComment] = useState("");

  useEffect(() => {
    dispatch(fetchComment(params.id));
  }, []);

  const [activeTab1, setActiveTabs] = useState("1");

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setActiveTabs(tab);
    }
  };

  const onEditCommentHandler = event => {
    event.preventDefault();
    setEditModal(false);
    let formData = new FormData();
    formData.append("comment", fetchCommentValue);
    formData.append("ticket_id", params.id);
    formData.append("comment_index", index);

    dispatch(EditCommentHandler(formData, params.id));
  };

  const deleteComment = (ticketId, index) => {
    var data = {
      ticketId: ticketId,
      index: index,
    };

    swal({
      text: "Are you sure you want to delete!",
      icon: "warning",
      buttons: true,
    }).then(willDelete => {
      if (willDelete) {
        dispatch(deleteCommentByIndex(data));
        swal(" Your comment has been deleted!", {
          icon: "success",
          timer: 2000,
        });
      }
    });
  };
  const onCommentSubmitHandler = () => {
    let formData = new FormData();
    formData.append("comment", comment);
    formData.append("ticket_id", params.id);


    dispatch(addComment(formData, params.id));


   
  };

  const changeValue = (event,editor)=>{
    const data = editor.getData();
   setComment(data)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem className="mb-3">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab1 === "1",
                })}
                onClick={() => {
                  toggle1("1");
                }}
              >
                Comments
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab1 === "2",
                })}
                onClick={() => {
                  toggle1("2");
                }}
              >
                Actvity
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab1 === "3",
                })}
                onClick={() => {
                  toggle1("3");
                }}
              >
                Commits
              </NavLink>
            </NavItem>
          </Nav>

          {activeTab1 == 1 && (
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <Form>
                      <CKEditor
                        editor={ClassicEditor}
                        data=""
                        onReady={editor => {
                          console.log("Editor is ready to use!", editor);
                        }}
                        // onChange={(event, editor) => {
                        //   const data = editor.getData();

                        //   setComment(data);
                        // }}
                        onChange={changeValue}
                      />
                    </Form>
                    <Row className="mt-3">
                      <Col lg="10">
                        <Button
                          onClick={onCommentSubmitHandler}
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </CardText>
                </Col>
              </Row>

              <div className="mt-5">
                <h5 className="font-size-15">
                  <i className="bx bx-message-dots text-muted align-middle me-1"></i>{" "}
                  Comments :
                </h5>

                <div>
                  {commentList &&
                    commentList.map((item, i) => (
                      <div className="media py-3" key={i}>
                        <div className="avatar-xs me-3">
                          <div className="avatar-title rounded-circle bg-light text-primary">
                            <i className="bx bxs-user"></i>
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="font-size-14 mb-1">
                            {item.author?.name}
                            <small className="text-muted float-end">
                              {item.timestamp?.slice(0, -4)}
                            </small>
                          </h5>
                          <div className="row">
                            <div className="col-12 d-flex">
                              <div
                                className="text-muted d-block col-10"
                                dangerouslySetInnerHTML={{
                                  __html: item.comment,
                                }}
                              />

                              <div className="col-2 justify-content-end d-flex">
                                <Link to="#" className="text-success">
                                  <i
                                    className="mdi mdi-pencil font-size-18"
                                    onClick={() => {
                                      editCommentIndex(i, item.comment);
                                    }}
                                  />
                                </Link>
                                <Link to="#" className="text-danger">
                                  <i
                                    className="mdi mdi-delete font-size-18"
                                    onClick={() => {
                                      deleteComment(item.ticket_id, i);
                                    }}
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </TabPane>
          )}

          {activeTab1 == 2 && (
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                <ActivityComp activity={props.ticket}/>
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab1 == 3 && (
            <TabPane tabId="2">
              <Row>
                {commits.length > 0 && (
                  <div
                    style={{
                      maxHeight: "28em",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Commit Id</th>
                          <th>Title</th>
                          <th>Date</th>
                          <th>Author</th>
                        </tr>
                      </thead>
                      <tbody>
                        {commits?.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {" "}
                              <h5>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.id.substring(0, 8)}
                                </a>
                              </h5>
                            </td>
                            <td> {item.title}</td>

                            <td>
                              {" "}
                              {moment(new Date(item?.timestamp)).format(
                                "DD MMM, YY"
                              )}
                            </td>
                            <td>{item.author.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Row>
            </TabPane>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={editModal} size="xl">
        <Row>
          <Col xs={11}>
            <h4 className="m-3">Edit Comment</h4>
          </Col>
          <Col>
            <i
              className="fas m-3 fa-times"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditModal(false);
              }}
            ></i>
          </Col>
        </Row>
        <hr></hr>

        <ModalBody>
          <Form onSubmit={onEditCommentHandler}>
            <FormGroup className="mb-4" row>
              <Label className="col-form-label col-lg-2">Description</Label>
              <Col lg="10">
                <CKEditor
                  editor={ClassicEditor}
                  data={fetchCommentValue}
                  onReady={editor => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFetchCommentValue(data);
                  }}
                />
              </Col>
            </FormGroup>

            <Button type="submit" color="primary">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Tabs;
