import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import React, { useState, useEffect } from "react";

import swal from "sweetalert";


import { ProfileSchema, PasswordSchema } from "./ProfileSchema";
import { useFormik } from "formik";
import { updatePassword } from "store/actions";
import { useDispatch } from "react-redux";

const initialValues = {
  password: "",
  confirmPassword: "",
};


const uid = localStorage.getItem('uid')
const Password = () => {
  const dispatch = useDispatch();
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: PasswordSchema,
      onSubmit: (values,action) => {

        updatePasswordFunction(values,action)
        // 

      },
    });

  const  updatePasswordFunction=(values,action)=>{

    let formData = new FormData();
    formData.append("password", values.password);
    formData.append("uid", uid);

    var data = {
      formData: formData,

    };

      dispatch(updatePassword(data));

      action.resetForm();

      swal("Poof! Your Password has been updated!", {
        icon: "success",
        timer:2000
      });

    }


  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup className="mb-4 d-flex justify-content-between" row>
        <div className="col-md-6 ">
          <label className="col col-form-label">Password</label>

          <input
            name="password"
            className="form-control"
            placeholder="Enter Password..."
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <input type="hidden" name="uid" value={uid} />

          {errors.password && touched.password ? (
            <small className="text-danger fs-6">{errors.password}</small>
          ) : (
            ""
          )}
        </div>

        <div className="col-md-6 ">
          <label className="col col-form-label">Confirm Password</label>

          <input
            name="confirmPassword"
            className="form-control"
            placeholder="Enter Confirm Password..."
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          {errors.confirmPassword && touched.confirmPassword ? (
            <small className="text-danger fs-6">{errors.confirmPassword}</small>
          ) : (
            ""
          )}
        </div>
      </FormGroup>

      <Button type="submit" color="primary">
        Submit
      </Button>
    </Form>
  );
};

export default Password;
