import * as Yup from "yup";

export const ProfileSchema = Yup.object({
  firstName: Yup.string().required("Please enter first name"),
  lastName: Yup.string().required("Please enter last name"),
  contactNumber: Yup.string().required("Please enter the contact number"),
  address: Yup.string().required("Please enter the address"),
});

export const PasswordSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().required('Confirm Password is required').oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
