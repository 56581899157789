import * as Yup from 'yup';

export const addTicketSchema = Yup.object({
    title:Yup.string().required('Please enter the title'),
    status:Yup.string().required('Please select the status'),
    type:Yup.string().required('Please select the type'),
    priority:Yup.string().required('Please select the priority'),
    skills:Yup.string().required('Please enter the skills'),
    original_estimate:Yup.string().required('Please enter the estimate time'),
    
    
})