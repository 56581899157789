import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Media } from "reactstrap";
import { Link } from "react-router-dom";
import { map } from "lodash";
import images from "assets/images";
import avtar1 from "../../../assets/images/users/avatar-2.jpg";
import Tabs from "./Tabs";


const Comments=(props)=>{

  return (
    <Card>
      <CardBody>

        <Tabs ticket={props.ticket}/>

      </CardBody>
    </Card>



  );
}
   
  



export default Comments;
