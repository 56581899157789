import Select from "react-select";
import React, { Component, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditTicket, fetchNewDetails, FetchUser } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import makeAnimated from "react-select/animated";

const EditTicketModal = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchUser());

  }, []);


  const userList = useSelector(state => state.projects.users);
  const animatedComponents = makeAnimated();

  const closeModalHandler = event => {
    props.closeModal(false);
  };


  userList.forEach(object => {
    object.label = object.first_name + " " + object.last_name;
    object.value = object.uid;
  });

  const result = userList.map(
    ({ _id, phone, email, user_image, first_name, last_name, ...rest }) => ({
      ...rest,
    })
  );

  const ticketData = props.ticketData;

  const [ticket_status, setStatus] = useState(ticketData.status);
  const [reporter, setReporter] = useState(ticketData.reporter);
  const [assignee, setAssignee] = useState(ticketData.assignee);
  const [description, setDescription] = useState(ticketData.description);
  const [type, setType] = useState(ticketData.type);
  const [priority, setPriority] = useState(ticketData.priority);
  const [skills, setSkills] = useState(ticketData.skills);
  const [time, setTime] = useState(ticketData.original_estimate);
  const [title, setTitle] = useState(ticketData.title);

  const managerOptions = result;
  const teamMembers = result;

  const onSetReporter = event => {
    setReporter(event);
  };

  const onsetAssignee = event => {
    setAssignee(event);
  };

  const onStatusValue = event => {
    setStatus(event.target.value);
  };

  const onTypeValue = event => {
    setType(event.target.value);
  };

  const onPriorityValue = event => {
    setPriority(event.target.value);
  };

  const onEstimateTime = event => {
    setTime(event.target.value);
  };

  const onSkillsValue = event => {
    setSkills(event.target.value);
  };

  const onTitleValue = event => {
    setTitle(event.target.value);
  };

  const onEditTicket = event => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("assignee", JSON.stringify(assignee));
    formData.append("title", title);
    formData.append("description", description);
    formData.append("reporter", JSON.stringify(reporter));
    formData.append("original_estimate", time);
    formData.append("project",  ticketData.project);
    formData.append("status", ticket_status);
    formData.append("skills", skills);
    formData.append("type", type);
    formData.append("priority", priority);
    formData.append("ticket_id", ticketData.ticket_id);
    props.closeModal(false);

    dispatch(fetchNewDetails(formData, ticketData.ticket_id));
  };



  return (
    <Modal isOpen={true} size="xl">
      <Row>
        <Col xs={11}>
          <h4 className="m-3">Edit Task</h4>
        </Col>
        <Col>
          <i
            className="fas m-3 fa-times"
            style={{ cursor: "pointer" }}
            onClick={closeModalHandler}
          ></i>
        </Col>
      </Row>
      <hr></hr>

      <ModalBody>
        <Form onSubmit={onEditTicket}>
          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">Title</Label>
            <Col lg="10">
              <Input
                name="title"
                value={title}
                onChange={onTitleValue}
                className="form-control"
                placeholder="Enter Project Title..."
              />
            </Col>
          </FormGroup>

          <FormGroup className="mb-4" row>
            <div className="col-md-5 offset-2">
              <label className="col-md-1 col-form-label">Type</label>

              <select
                className="form-control"
                onChange={onTypeValue}
                value={type}
                name="type"
              >
                <option>Bug</option>
                <option>Task</option>
                <option>Feature</option>
                <option>Epic</option>
              </select>
            </div>

            <div className="col-md-5">
              <label className="col-md-1 col-form-label">Priority</label>

              <select
                className="form-control"
                onChange={onPriorityValue}
                value={priority}
                name="priority"
              >
                <option>Low</option>
                <option>Medium </option>
                <option>High</option>
                <option>Highest</option>
              </select>
            </div>
          </FormGroup>

          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">Description</Label>
            <Col lg="10">
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onReady={editor => {
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup className="mb-4" row>
            <div className="col-md-5 offset-2">
              <label className="col-md-1 col-form-label">Reporter</label>

                <Select
                  defaultValue={reporter}
                  onChange={onSetReporter}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  options={managerOptions}
                  isMulti
                />
            </div>

            <div className="col-md-5">
              <label className="col-md-1 col-form-label">Assignee</label>

              <Select
                defaultValue={assignee}
                onChange={onsetAssignee}
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={teamMembers}
                isMulti
              />
            </div>
          </FormGroup>

          <FormGroup className="mb-4 d-flex justify-content-between" row>
            <div className="col-md-3 offset-2">
              <label className="col-md-1 col-form-label">Status</label>
              <select
                className="form-control"
                onChange={onStatusValue}
                value={ticket_status}
                name="status"
              >
                <option>Open</option>
                <option>In Progress</option>
     
              </select>
            </div>

            <div className="col-md-3 ">
              <label className="col-md-1 col-form-label">skills</label>

              <Input
                name="skills"
                value={skills}
                onChange={onSkillsValue}
                className="form-control"
                placeholder="Enter skills..."
              />
            </div>

            <div className="col-md-3 ">
              <label className="col-form-label">Estimate time</label>
              <Input
                name="title"
                type="text"
                onChange={onEstimateTime}
                className="form-control"
                placeholder="Enter Time..."
                value={time}
              />
            </div>
          </FormGroup>
          <button type="Submit" className="btn btn-primary">
            Submit
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditTicketModal;
