import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  addNewProject,
  FetchCustomer,
  FetchUser,
  updateProject,
} from "../../store/projects/actions";
import CardProject from "./card-project";
import { getProjectDetail } from "store/actions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
  Modal,
} from "reactstrap";

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import makeAnimated from "react-select/animated";
import Dropzone from "react-dropzone";
import { isEmpty } from "lodash";

const EditProject = props => {
  var param = useParams();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState("");
  const [detail, setDetail] = useState("");
  const [project_status, setStatus] = useState();
  const [manager, setManager] = useState([]);
  const [member, setTeamMember] = useState([]);
  // const [attachments, setAttachments] = useState();
  const [logo, setLogo] = useState();
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [code, setCode] = useState();

  const history = props.history;
  const dispatch = useDispatch();
  const userList = useSelector(state => state.projects.users);
  const projectDetails = useSelector(state => state.projects.projectDetail);

  // console.log(projectDetails);
  userList.forEach(object => {
    object.label = object.first_name + " " + object.last_name;
    object.value = object.uid;
  });

  useEffect(() => {
    dispatch(FetchUser());
    dispatch(getProjectDetail(param.id));
    dispatch(FetchCustomer());
  }, []);

  const customerList = useSelector(state => state.projects.customer);



  customerList.forEach(object => {
    object.label = object.first_name;
    object.value = object.uid;
  });

  const customerSelected = projectDetails.team_members?projectDetails.team_members.filter(item=>{
     return item.role =='customer'
  }):[]

 console.log(customerSelected)

 const teamExtracted = projectDetails.team_members?projectDetails.team_members.filter(item=>{
  return item.role !='customer'
}):[]

  useEffect(() => {
    setTitle(projectDetails.title);
    setDetail(projectDetails.project_details);
    setDescription(projectDetails.short_description);
    setstartDate(projectDetails.start_date);
    setendDate(projectDetails.end_date);
    setCode(projectDetails.project_code);
    setManager(projectDetails.manager);
    setTeamMember(projectDetails.team_members);
    setStatus(projectDetails.status);
  }, [projectDetails]);

  const animatedComponents = makeAnimated();

  const managerOptions = userList;
  const teamMembers = managerOptions;

  const onFileChange = e => {
    setAttachment(e.target.files);
  };

  const onDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const onCustomerChange = e => {
    setTeamMember(member => [...member, e]);
  };

  const onLogoChange = e => {
    setLogo(e.target.files[0]);
  };

  const onTitleValue = event => {
    setTitle(event.target.value);
  };

  const onStatusValue = event => {
    setStatus(event.target.value);
  };

  const onManagerValue = event => {
    setManager(event);
  };

  const onMemberChange = event => {
    setTeamMember(event);
  };

  const onCodeValue = event => {
    setCode(event.target.value);
  };

  const startDateChange = event => {
    setstartDate(event.target.value);
  };

  const endDateChange = event => {
    setendDate(event.target.value);
  };

  const onEditProject = event => {
    event.preventDefault();
    let formData = new FormData();

    formData.append("_id", param.id);
    formData.append("title", title);
    formData.append("project_code", code);
    formData.append("short_description", description);
    formData.append("project_details", detail);
    formData.append("status", project_status);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);

    console.log("manager ->", manager);
    console.log("member ->", member);

    if (manager?.length == 0) {
      formData.append("manager", JSON.stringify(projectDetails.manager));
    } else {
      formData.append("manager", JSON.stringify(manager));
    }

    if (member?.length == 0) {
      formData.append(
        "team_members",
        JSON.stringify(projectDetails.team_members)
      );
    } else {
      formData.append("team_members", JSON.stringify(member));
    }
    formData.append("logo", logo);

    var data = {
      formData: formData,
      history: history,
    };
    dispatch(updateProject(data, param.id));
  };

  return (
    <React.Fragment>
      {projectDetails && (
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Form onSubmit={onEditProject}>
                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Project Title
                        </Label>
                        <Col lg="10">
                          <Input
                            name="title"
                            value={title}
                            onChange={onTitleValue}
                            className="form-control"
                            placeholder="Enter Project Title..."
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Project Code
                        </Label>
                        <Col lg="10">
                          <Input
                            name="title"
                            value={code}
                            onChange={onCodeValue}
                            className="form-control"
                            placeholder="Enter Project Code..."
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label className="col-form-label col-lg-2">Logo</label>

                        <Col lg="10">
                          <Input
                            name="logo"
                            onChange={onLogoChange}
                            type="file"
                            placeholder=""
                            className="form-control form-control-lg"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Project Detail
                        </Label>
                        <Col lg="10">
                          <CKEditor
                            editor={ClassicEditor}
                            data={detail ? detail : ""}
                            onReady={editor => {
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDetail(data);
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label className="col-md-2 col-form-label">
                          Short Description
                        </label>
                        <div className="col-md-10">
                          <textarea
                            className="form-control"
                            onChange={onDescriptionChange}
                            value={description}
                          ></textarea>
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label className="col-md-2 col-form-label">
                          Status
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            onChange={onStatusValue}
                            value={project_status}
                            name="status"
                          >
                            <option>Pending</option>
                            <option>In Progress </option>
                            <option>Completed</option>
                          </select>
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Project Date
                        </Label>
                        <Col lg="10">
                          <Row>
                            <Col md={6} className="pr-0">
                              <input
                                type="date"
                                className="form-control"
                                value={startDate}
                                name="start_date"
                                onChange={startDateChange}
                              />
                            </Col>
                            <Col md={6} className="pl-0">
                              <input
                                type="date"
                                className="form-control"
                                value={endDate}
                                name="end_date"
                                onChange={endDateChange}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label className="col-form-label col-lg-2">
                          Manager
                        </label>
                        <Col lg="10">
                          {projectDetails.manager && manager && (
                            <Select
                              onChange={onManagerValue}
                              defaultValue={projectDetails.manager}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={managerOptions}
                            />
                          )}
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label className="col-form-label col-lg-2">
                          Team Members
                        </label>
                        <Col lg="10">
                          {projectDetails.team_members && member && (
                            <Select
                              closeMenuOnSelect={false}
                              onChange={onMemberChange}
                              components={animatedComponents}
                              isMulti
                              options={teamMembers}
                              defaultValue={teamExtracted}
                            />
                          )}
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <label className="col-form-label col-lg-2">
                          Customers
                        </label>
                        <Col lg="10">
                          <Select
                            closeMenuOnSelect={false}
                            onChange={onCustomerChange}
                            components={animatedComponents}
                            options={customerList}
                            defaultValue={customerSelected}
                          />
                        </Col>
                      </FormGroup>

                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button
                            type="submit"
                            onClick={onEditProject}
                            color="primary"
                          >
                            Update Project
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditProject;
