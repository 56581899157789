import React, { Component, useState } from "react";
import PropTypes from "prop-types";

import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
import { facebook, google } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";

import { url } from "store/constant/base_url";
const Login = () => {
  const error = useSelector(state => state.login.error);

  const history = useHistory();
  const baseUrl = url;
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isValidName, setIsValidName] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const nameHandler = e => {
    if (e.target.value) {
      setIsValidName(false);
    } else {
      setIsValidName(true);
    }
    setUserName(e.target.value);
  };
  const passwordHandler = e => {
    if (e.target.value) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }
    setPassword(e.target.value);
  };
  const loginHandler = event => {
    event.preventDefault();
    var data = {
      username: userName,
      password: password,
    };
    dispatch(loginUser(data, history));
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error != "" && <Alert color="danger">{error}</Alert>}

                    <form className="form-horizontal" onSubmit={loginHandler}>
                      <div className="mb-1">
                        <label className="form-label">
                          User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="username"
                          type="text"
                          className="form-control"
                          onChange={nameHandler}
                          onBlur={() => {
                            if (userName.length == 0) {
                              setIsValidName(true);
                            }
                          }}
                        />
                      </div>

                      {isValidName && (
                        <small className="text-danger fs-6">
                          User Name is required
                        </small>
                      )}
                      <div className="mb-1">
                        <label className="form-label">
                          Password <span className="text-danger">*</span>
                        </label>
                        <div className="input-group auth-pass-inputgroup">
                          <input
                            name="password"
                            type="password"
                            autoComplete="true"
                            className="form-control"
                            onChange={passwordHandler}
                            onBlur={() => {
                              if (userName.length == 0) {
                                setIsValidPassword(true);
                              }
                            }}
                          />
                          <button
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {isValidPassword && (
                          <small className="text-danger fs-6">
                            Password is required
                          </small>
                        )}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={
                            userName.length == 0 || password.length == 0
                          }
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
