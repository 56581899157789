import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { getTicketDetail } from "store/projects/actions";
import ProjectDetail from "./projectDetail";
import TeamMembers from "./teamMembers";
import OverviewChart from "./overviewChart";
import { options, series } from "common/data/projects";
import AttachedFilesTicket from "./attachedFilesTicket";
import Comments from "./comments";
import Tabs from "./Tabs";
import TopCities from "pages/Dashboard/TopCities";

const ticketOverview = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [header, setHeader] = useState("Project");

  useEffect(() => {
    dispatch(getTicketDetail(params.id));
  }, []);

  const ticketDetail = useSelector(state => state.projects.ticketDetail);

  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
        

          {!isEmpty(ticketDetail) && (
            <>
              <Row>
                <Col lg="8">
                  <ProjectDetail variable={"ticket"} param={params.name} paramId = {params.id}  ticket={ticketDetail} />
                  <Comments ticket={ticketDetail}/>

                </Col>

                <Col lg="4">
                  <AttachedFilesTicket files={ticketDetail.attachments} />
                  <TopCities  ticket={ticketDetail}/>
                </Col>
                
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ticketOverview;
