import { addTicketSchema } from "./ticket-schema";
import React, { useState } from "react";

import { AddTicket } from "store/actions";
import Dropzone from "react-dropzone";

import {
  Button,
  Card,
  Col,
  Row,
  Label,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import makeAnimated from "react-select/animated";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Select from "react-select";

const initialValues = {
  title: "",
  type: "",
  priority: "",
  skills: "",
  status: "",
 
  original_estimate: "",
};

const TypeArray = ["Bug", "Task", "Feature", "Epic"];
const StatusArray = ["Open", "In Progress"];
const PriorityArray = ["Low", "Medium", "High", "Highest"];

const AddTicketModal = props => {
  const [attachments, setAttachments] = useState([]);

  const [reporter, setReporter] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [description, setDescription] = useState("");

  // validation

  const [isReporter, setIsReporter] = useState(false);
  const [isAssignee, setIsAssignee] = useState(false);
  const [isDescription, setIsDescription] = useState(false);

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setAttachments(files);
  };

  const onSetReporter = e => {
    if (e) {
      setIsReporter(false);
    } else {
      setIsReporter(true);
    }

    setReporter(e);
  };

  const onsetAssignee = e => {
    if (e) {
      setIsAssignee(false);
    } else {
      setIsAssignee(true);
    }

    setAssignee(e);
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addTicketSchema,
      onSubmit: values => {
        onCreateTicket(values)
      },
    });

  const dispatch = useDispatch();

  const animatedComponents = makeAnimated();

  const onCreateTicket = values => {
  
    


    let formData = new FormData();
    formData.append("assignee", JSON.stringify(assignee));
    formData.append("title", values.title);
    formData.append("description", description);
    formData.append("reporter", JSON.stringify(reporter));
    formData.append("original_estimate", values.original_estimate);
    formData.append("project", props.projectId);
    formData.append("status", values.status);
    formData.append("skills", values.skills);
    formData.append("type", values.type);
    formData.append("priority", values.priority);

    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        formData.append("attachment", attachments[i]);
      }
    }

    dispatch(AddTicket(formData, props.projectId));
    props.closeAddModal()
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">Title</Label>
          <Col lg="10">
            <Input
              name="title"
              className="form-control"
              placeholder="Enter Project Title..."
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.title && touched.title ? (
              <small className="text-danger fs-6">{errors.title}</small>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>

        <FormGroup className="mb-4" row>
          <div className="col-md-5 offset-2">
            <label className="col-md-1 col-form-label">Type</label>

            <select
              className="form-control"
              name="type"
              value={values.type}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option hidden selected>
                Select{" "}
              </option>
              {TypeArray.map((item, i) => (
                <option key={i}>{item}</option>
              ))}
            </select>

            {errors.type && touched.type ? (
              <small className="text-danger fs-6">{errors.type}</small>
            ) : (
              ""
            )}
          </div>

          <div className="col-md-5">
            <label className="col-md-1 col-form-label">Priority</label>

            <select
              className="form-control"
              name="priority"
              value={values.priority}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option hidden selected>
                Select{" "}
              </option>
              {PriorityArray.map((item, i) => (
                <option key={i}>{item}</option>
              ))}
            </select>

            {errors.priority && touched.priority ? (
              <small className="text-danger fs-6">{errors.priority}</small>
            ) : (
              ""
            )}
          </div>
        </FormGroup>

        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">Description</Label>
          <Col lg="10">
            <CKEditor
              editor={ClassicEditor}
              data=""
              onReady={editor => {
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
              onBlur={() => {
                if (description.length == 0) {
                  setIsDescription(true);
                }
              }}
            />

            {isDescription && description.length == 0 ? (
              <small className="text-danger fs-6">
                Please fill the description
              </small>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>

        <FormGroup className="mb-4" row>
          <div className="col-md-5 offset-2">
            <label className="col-md-1 col-form-label">Reporter</label>

            <Select
              onChange={onSetReporter}
              closeMenuOnSelect={false}
              components={animatedComponents}
              options={props.manager}
              isMulti
              onBlur={() => {
                if (reporter.length == 0) {
                  setIsReporter(true);
                }
              }}
            />

            {isReporter && (
              <small className="text-danger fs-6">Reporter is required</small>
            )}
          </div>

          <div className="col-md-5">
            <label className="col-md-1 col-form-label">Assignee</label>

            <Select
              onChange={onsetAssignee}
              closeMenuOnSelect={false}
              components={animatedComponents}
              options={props.members}
              isMulti
              onBlur={() => {
                if (assignee.length == 0) {
                  setIsAssignee(true);
                }
              }}
            />

            {isAssignee && (
              <small className="text-danger fs-6">Assignee is required</small>
            )}
          </div>
        </FormGroup>

        <FormGroup className="mb-4 d-flex justify-content-between" row>
          <div className="col-md-3 offset-2">
            <label className="col-md-1 col-form-label">Status</label>
            <select
              className="form-control"
              name="status"
              value={values.status}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option hidden selected>
                Select{" "}
              </option>
              {StatusArray.map((item, i) => (
                <option key={i}>{item}</option>
              ))}
            </select>

            {errors.status && touched.status ? (
              <small className="text-danger fs-6">{errors.status}</small>
            ) : (
              ""
            )}
          </div>

          <div className="col-md-3 ">
            <label className="col-md-1 col-form-label">skills</label>
            <Input
              name="skills"
              className="form-control"
              placeholder="Enter skills..."
              value={values.skills}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.skills && touched.skills ? (
              <small className="text-danger fs-6">{errors.skills}</small>
            ) : (
              ""
            )}
          </div>

          <div className="col-md-3 ">
            <label className="col-form-label">Estimate time</label>
            <Input
              name="original_estimate"
              type="text"
              className="form-control"
              placeholder="Enter Time..."
              value={values.original_estimate}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.original_estimate && touched.original_estimate ? (
              <small className="text-danger fs-6">
                {errors.original_estimate}
              </small>
            ) : (
              ""
            )}
          </div>
        </FormGroup>

        <FormGroup className="mb-4" row>
          <label className="col-form-label col-lg-2">Attachement</label>

          <Col lg="10">
            <Dropzone
              onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>

            <div className="dropzone-previews mt-3" id="file-previews">
              {attachments.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <a to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </a>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
          </Col>
        </FormGroup>

        <Button type="submit" color="primary">
          Submit
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default AddTicketModal;
