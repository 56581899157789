import React, { Component } from "react"
import { Doughnut } from "react-chartjs-2"

const CustomDonutChart =(props)=> {
    const value = props.value;

    

    const count=[];
    const labels=[];
   

 if(value=='Priority'){
    props.priority?.forEach(element => {
        count.push(element.count)
    });

    props.priority?.forEach(element => {
        labels.push(element.priority)
    });
 }else if(value=='Status'){
    props.status?.forEach(element => {
        count.push(element.count)
    });

    props.status?.forEach(element => {
        labels.push(element.status)
    });
 }else if(value=='Type') {
     
    props.type?.forEach(element => {
        count.push(element.count)
    });

    props.type?.forEach(element => {
        labels.push(element.type)
    });
 }



    const data = {
      labels: [...labels],  
      datasets: [
        {
          data: [...count],
          backgroundColor: ["#34c38f", "#556ee6", ],
          
          hoverBackgroundColor: ["#34c38f", "#556ee6"],
          // hoverBorderColor: "#fff",
        },
      ],
    }
    

    return (
        <React.Fragment>
          <Doughnut width={474} height={260} data={data} />
        </React.Fragment>
      )
  
}

export default CustomDonutChart
