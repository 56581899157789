import React, { Component } from "react";
import { Card, CardBody, CardTitle, Media } from "reactstrap";
import { Link } from "react-router-dom";

const ActivityComp = props => {
  const activity = props.activity.activity?props.activity.activity:[];


  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Activity</CardTitle>
          {activity.map((item, i) => (
            <ul className="verti-timeline list-unstyled" key={i}>
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18"></i>
                </div>
                <Media>
                  {/* <div className="mr-3">
                                   <h5 className="font-size-14">22 Nov <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i></h5>
                               </div> */}
                  <Media body>
                    <div
                      className="mb-2"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  </Media>
                </Media>
              </li>
            </ul>
          ))}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ActivityComp;
