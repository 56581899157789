import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIcons } from "store/actions";
import axios from "axios";
import { url } from "store/constant/base_url";
import classes from '../projects.module.css'
const Icon = props => {

const [iconList,setIconList] = useState('')

    const baseUrl = url;


    useEffect(() => {

        var url = `${baseUrl}/ticket/get_icon/${props.typeValue}/${props.type.toLowerCase()}`
        axios({
            method: 'get',
            url: url,
            headers: {"Authorization" : `Bearer ${localStorage.getItem('token')}`}
            })
        .then((response) => {
            if (response.status >= 200 || response.status <= 299)
            setIconList(response.data)
        
        })
        .catch((err) => {
            if (err.response.data.status == "error") {
            throw "Oops Something went wrong";
            }
        });
          

        // dispatch(getIcons(props.type.toLowerCase(),props.typeValue));
      }, []);

  return (
    <React.Fragment>
     
      
        <div style={{width:'20px'}}>
        { iconList &&  <div style={{width:'100% !important'}} className={classes['svg-main']}
                dangerouslySetInnerHTML={{
                  __html: iconList,
                }}
            />}


        </div>

     
    </React.Fragment>
  );
};

export default Icon;
