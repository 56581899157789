import React from 'react';
import { Card, CardBody, CardTitle, Progress } from "reactstrap";

 const TopCities = (props) => {



    const ticket = props.ticket? props.ticket:[]
          return (
              <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            Time Tracking
                        </CardTitle>
                 

                        <div className="table-responsive mt-4">
                            <table className="table table-centered table-nowrap mb-2">
                                <tbody>
                                {ticket.original_estimate &&    <tr>
                                        <td style={{ width: "30%" }} >
                                            <p className="mb-0">Original Estimate</p>
                                        </td>

                                        <td >
                                            <Progress value="100" color="primary" className="bg-transparent mt-2" size="sm" />
                                        </td>

                                        <td style={{ width: "25%" }} >
                                            <p className="mb-0 ">{ticket.original_estimate}</p></td>
                                     
                                    </tr>}

                               {  ticket.effort_estimate &&   <tr>
                                        <td style={{ width: "30%" }} >
                                            <p className="mb-0">Effort Estimate</p>
                                        </td>
                                        <td>
                                            <Progress value="100" color="primary" className="bg-transparent mt-2" size="sm" />
                                        </td>

                                        <td style={{ width: "25%" }} >
                                            <p className="mb-0 ">{ticket.effort_estimate}</p></td>
                                       
                                    </tr>}

                            
                               
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
          );
        }

export default TopCities;