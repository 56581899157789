import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { Badge, Card, CardBody, Col, Progress } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteProject } from "store/actions";
import { useEffect } from "react";
import { url } from "store/constant/base_url";
import * as moment from "moment";
import classes from "./projects.module.css";

import {
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Media,
  Container,
  Row,
} from "reactstrap";
import { getProjectDetail } from "store/actions";

import { getProjects } from "store/actions";

const CardProject = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects());
    // dispatch(fetchUsers());
  }, []);

  const deleteProjectById = id => {
    dispatch(deleteProject(id));
  };

  const descriptioOverFlow = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    webkitLineClamp: "2",
    webkitBoxOrient: "vertical",
    whiteSpace: "normal",
  };
  const displayWebKit = {};

  const base_url = url;
  const projectsList = useSelector(state => state.projects.projects);

  const test = projectsList.map(item => {
    return item.team_members;
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <Link
                to="/projects-create"
                className="d-flex justify-content-end col-2 ms-auto"
              >
                <button className="btn btn-primary mb-2">Add Project</button>
              </Link>
            </div>
          </div>

          <div className="row col-12">
            {projectsList.map((project, key) => (
              <Col xl="4" className="px-3" sm="6" key={"_project_" + key}>
                <Card>
                  <CardBody style={{ height: "18em" }}>
                    <UncontrolledDropdown className="float-right d-flex justify-content-end ">
                      <DropdownToggle href="#" className="arrow-none" tag="i">
                        <i
                          className="mdi mdi-dots-vertical m-0 text-muted h5"
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            top: "15px",
                          }}
                        ></i>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <Link to={`edit-project/${project._id}`}>
                          <DropdownItem
                          // onClick={() => {
                          //   editProject(project._id);
                          // }}
                          >
                            Edit
                          </DropdownItem>
                        </Link>
                        <DropdownItem
                          onClick={() => {
                            deleteProjectById(project._id);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    {}
                    <Media>
                      {project.logo && (
                        <div className="avatar-md  me-2">
                          <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                            <img
                              src={`${base_url}/user/fetch_profile_image/${project.logo.value}`}
                              alt=""
                              style={{ borderRadius: "50px", width: "60px" }}
                            />
                          </span>
                        </div>
                      )}

                      <Media className="overflow-hidden" body>
                        <h5 className="text-truncate font-size-15">
                          <Link
                            to={`project-overview/${project._id}`}
                            className="text-dark"
                          >
                            {project.title}
                          </Link>
                        </h5>

                        <span className={classes.overFlowTextLine3}>
                          {project.short_description}
                        </span>

                        <div className="row">
                          <Col sm="6" xs="6">
                            <div className="mt-4">
                              <span className="font-size-10">
                                <i className="bx bx-calendar me-1 text-primary" />{" "}
                                Start Date
                              </span>
                              <p className="text-muted mb-0">
                                {moment(new Date(project?.start_date)).format(
                                  "DD MMM, YY"
                                )}
                              </p>
                            </div>
                          </Col>

                          <Col sm="6" xs="6" className="mb-2">
                            <div className="mt-4">
                              <span className="font-size-10">
                                <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                                Due Date
                              </span><br></br>
                              {moment(new Date(project.end_date)).format(
                                "DD MMM, YY"
                              )}
                            </div>
                          </Col>

                      {project.completion &&    <Progress color="success" value={project.completion}>{`${project.completion}%`}</Progress>}

                        </div>
                        <div className="avatar-group">
                          {/* {project.team_members?.map((team, secondkey) =>
                              !team.image || team.image !== "Null" ? (
                                <React.Fragment key={"_teamaa__" + secondkey}>
                                  <div className="avatar-group-item">
                                    <Link
                                      to="#"
                                      className="d-inline-block"
                                      id={"member" + team.id}
                                      key={"_team_" + secondkey}
                                    >
                                      <img
                                        src={`${base_url}/user/fetch_profile_image/${team.image}`}
                                        className="rounded-circle avatar-xs"
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <React.Fragment key={"__teama_" + secondkey}>
                                  <div className="avatar-group-item">
                                    <Link
                                      to="#"
                                      className="d-inline-block"
                                      id={"member" + team.id}
                                    >
                                      <div className="avatar-xs">
                                        <span
                                          className={
                                            "avatar-title rounded-circle bg-primary text-white font-size-16"
                                          }
                                        >
                                          {team.first_name +
                                            " " +
                                            team.last_name}
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </React.Fragment>
                              )
                            )} */}
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                  <div className="py-3 border-top">
                    <div className="row col-12">
                      <div className="col-8">
                        <ul className="list-inline mb-0 px-4">
                          <li className="list-inline-item mr-3">
                            <Badge
                              color={
                                project.status == "In Progress"
                                  ? "warning"
                                  : project.status == "Completed"
                                  ? "success"
                                  : "danger"
                              }
                              className=""
                            >
                              {project.status}
                            </Badge>
                          </li>
                          <li className="list-inline-item mr-3" id="dueDate">
                            <i className="bx bx-calendar mr-1"></i>{" "}
                            {moment(new Date(project.end_date)).format(
                              "DD MMM, YY"
                            )}
                          </li>
                          <li className="list-inline-item mr-3" id="comments">
                            {project.comments}
                          </li>
                        </ul>
                      </div>
                      <div className="col-4">
                        <Link
                          to={`/view-tickets/${project.title}/${project._id}`}
                          style={{ float: "right" }}
                        >
                          <Badge color="primary" className="py-2">
                            View Tickets
                          </Badge>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CardProject;
