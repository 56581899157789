import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"




//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"




//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  login,
  Account,
  ForgetPassword,
  Profile,
  projects,
  tasks,
  Dashboard,
  DashboardSaas
})

export default rootReducer
