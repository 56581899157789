import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

const CustomSplineChart = props => {


  const week = [];


  props.graphDetail.data?.forEach(element => {
    week.push(element.name)
  });


 
  const series = 
  props.graphDetail.data.map((ele)=>{
   return {name:ele.name,data:ele.data}
  })

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"," #FFC300 "],
    xaxis: {
      type: "string",
      categories:  props.graphDetail.categories,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="350"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default CustomSplineChart;
