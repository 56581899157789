import React, { useEffect } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import { url } from "store/constant/base_url";
import { fetchUserInformation } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

const WelcomeComp = props => {
  const image = localStorage.getItem("image");

  const uid = localStorage.getItem("uid");
const dispatch = useDispatch();

  const name =
    localStorage.getItem("first_name") +
    " " +
    localStorage.getItem("last_name");

    useEffect(() => {
      dispatch(fetchUserInformation(uid));
    }, []);

    const userDetails = useSelector(state => state.projects.userDetail);


  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className=" bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Founda Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="8">
              <div className="avatar-md profile-user-wid  ">
                <img
                  src={`${url}/user/fetch_profile_image/${image}`}
                  alt=""
                  style={{height: '100%',
                    display: 'block',
                    width: '100%'
                    }}
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h6 className="font-size-15 ">{name}</h6>
            { userDetails.length && <p className="text-muted mb-0 text-truncate">
              {userDetails[0]["designation"]}{" "}
              </p>}
            </Col>

            <Col sm="4">
              <div className="mt-4 text-end">
                <Link
                  to="/profile"
                  className="btn btn-primary waves-effect waves-light btn-sm"
                >
                  View Profile <i className="mdi mdi-arrow-right ml-1"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
