import React, {useState, Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { map } from "lodash";
import { url } from "store/constant/base_url";
import Dropzone from "react-dropzone"
import { useDispatch } from "react-redux";
import { addAttachmentTicket, deleteAttachmentTicket } from "store/actions";
import swal from "sweetalert";

const AttachedFilesTicket = props => {
  const dispatch = useDispatch()
  const base_url = url;
  const params = useParams()
  const [attachments, setAttachments] = useState([]);

  const deleteAttachmentHandler=(id)=>{
    swal({
      text: "Are you sure you want to delete!",
      icon: "warning",
      buttons: true,
    }).then(willDelete => {
      if (willDelete) {
        dispatch(deleteAttachmentTicket(id,params.id));
        swal("Poof! Your attachment has been deleted!", {
          icon: "success",
          timer:2000
        });
      }
    });

  }

  const  handleAcceptedFiles = (files) => {

    if(files.length){
      let formData = new FormData();
      formData.append("ticket_id", params.id);
      for (let i = 0; i < files.length; i++) {
        formData.append("attachment", files[i]);
      }
      
      dispatch(addAttachmentTicket(formData,params.id));
    }
      

  }

  return (
    <Card >
      <CardBody>
        <CardTitle className="mb-4">Attached Files</CardTitle>

        <Dropzone  style={{height:'10px !important'}} onDrop={acceptedFiles => {

           handleAcceptedFiles(acceptedFiles)

        }}>
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" style={{minHeight:'30px !important'}} >
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="mb-3" style={{height:'10px !important'}}>
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <small className="fs-5">Drop files here or click to upload.</small>
              </div>
            </div>
          )}
        </Dropzone>

        <div className="table-responsive" style={{maxHeight: '21vh',overflowY: 'auto'}}>
          <Table className="table table-nowrap align-middle table-hover mb-0">
            <tbody>
              {map(props.files, (file, i) => (
                <tr key={"_file_" + i}>
                  <td style={{ width: "45px" }}>
                    <div className="avatar-sm">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                        <i className="bx bxs-file-doc" />
                      </span>
                    </div>
                  </td>
                  <td>
                    <h5 className="font-size-14 mb-1">
                      <Link to="#" className="text-dark">
                        {file.title}
                      </Link>
                    </h5>
                  </td>
                  <td>
                    <div className="text-center">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        download={file.title}
                        href={`${base_url}/project/fetch_project_file/${file.value}`}
                        className="text-dark"
                      >
                        <i className="bx bx-download h3 m-0" />
                     
                      </a>
                      <i className="bx bx-trash h4 ms-3" onClick={()=>{
                          deleteAttachmentHandler(file.value)
                        }} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default AttachedFilesTicket;
