import { keys } from "lodash";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

const CustomColumnChart = props => {



  const status = [];

  props.graphDetail.data?.forEach(element => {
    status.push(element.name)
  });

  

  const series = 
    props.graphDetail.data.map((ele)=>{
     return {name:ele.name,data:ele.data}
    })




  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#34c38f", "#556ee6", "#f46a6a"," #FFC300 "],
    xaxis: {
      categories:  props.graphDetail.categories,
    },
    yaxis: {
      title: {
        text: "tickets",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val ;
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default CustomColumnChart;
