import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { calendarSchema } from "./calendar-schema";
import { useFormik } from "formik";
import {
  addEvent,
  deleteAddedEvent,
  editEventSubmit,
  fetchSingleEvent,
  FetchUser,
  getAllEvents,
} from "../../store/projects/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";

const initialValues = {
  title: "",
  from_date: null,
  to_date: null,
  uid:localStorage.getItem('uid')
};

const event1 = {
  id: 0,
  title: "",
  title_category: "",
  start: "",
  className: "",
  category: "",
  event_category: "",
};
const Index = props => {
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [eventId, setId] = useState("");

  const [calendarEvents, setCalendarEvents] = useState();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modalcategory, setModalcategory] = useState(false);
  const [event, setEvent] = useState(event1);
  const [selectedDay, setSelectedDay] = useState(0);

  const calendarComponentRef = React.createRef();
  const dispatch = useDispatch();

  const userList = useSelector(state => state.projects.users);
  const singleEvent = useSelector(state => state.projects.singleEvent);

  console.log(singleEvent)

  userList?.forEach(object => {
    object.label = object.first_name + " " + object.last_name;
    object.value = object.uid;
  });

  const result = userList?.map(
    ({ _id, phone, email, user_image, first_name, last_name, ...rest }) => ({
      ...rest,
    })
  );

  const eventList = useSelector(state => state.projects.events);


  const startHandler = e => {
    setStartDate(e.target.value);
  };

  const endHandler = e => {
    setEndDate(e.target.value);
  };

  const memberHandler = e => {
    setMember(e.target.value);
  };

  const titleHandler = e => {
    setTitle(e.target.value);
  };

  const deleteEventHandler = (id) => {
    swal({
      text: "Are you sure you want to delete!",
      icon: "warning",
      buttons: true,
    }).then(willDelete => {
      if (willDelete) {
        dispatch(deleteAddedEvent(id, localStorage.getItem('uid')));
        swal("Poof! Your event has been deleted!", {
          icon: "success",
          timer: 2000,
        });
        setEditModal(false);
      }
    });
  };


  const DefaultEvents = eventList.map((item, i) => {
    var startDate = new Date().setDate(new Date(item.from_date).getDate());
    var endDate = new Date().setDate(new Date(item.to_date).getDate());

    return {
      id: item._id,
      title: `${item.username}  (${item.uid}) ${item.title }  `,
      start: startDate,
      end: endDate,
      backgroundColor: item.uid,

      className: "bg-success",
    };
  });

  const editSubmit = event => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("id", eventId);
    formData.append("from_date", startDate);
    formData.append("to_date", endDate);
    formData.append("uid", localStorage.getItem('uid'));
    formData.append("title", title);

    dispatch(editEventSubmit(formData));

    setEditModal(false)
 
  };

  useEffect(() => {
    dispatch(getAllEvents());
    setCalendarEvents(DefaultEvents);

    // dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    dispatch(FetchUser());
  }, []);

  useEffect(() => {
    setCalendarEvents(DefaultEvents);
  }, [eventList]);

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: calendarSchema,
      onSubmit: (values, action) => {
        let formData = new FormData();

        formData.append("title", values.title);
        formData.append("from_date", values.from_date);
        formData.append("to_date", values.to_date);
        formData.append("uid", values.uid);

        dispatch(addEvent(formData));
        action.resetForm();
         setAddModal(false)
       },
    });

  const teamMembers = result ? result : [];

  /**
   * Handling the modal state
   */
  function toggle() {
    setAddModal(true);
  }

  function toggle1() {
    setEditModal(true);
  }

  function togglecategory() {
    setModalcategory(!modalcategory);
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
     setAddModal(true)

    setSelectedDay(arg);
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {

    dispatch(fetchSingleEvent(arg.event.id))
  

    setTitle(singleEvent[0]['title']);
    setEditModal(true);



    var startDate = arg.event.start;
    var endDate = arg.event.end;

    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }  

    var convertedStartDate = formatDate(startDate);
    var convertedEndDate = formatDate(endDate);
    setStartDate(convertedStartDate);


    if (convertedEndDate == "1970-01-01") {
      convertedEndDate = convertedStartDate;

      setEndDate(convertedEndDate);
    } else {
      setEndDate(convertedEndDate);
    }

    setId(arg.event.id);
   
  };

  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (e, values) => {
    var newEvent = {};

    newEvent = {
      id: calendarEvents.length + 1,
      title: values["title"],
      start: selectedDay ? selectedDay.date : new Date(),
      className: values.category + " text-white",
    };

    // save new event
    setCalendarEvents(calendarEvents.concat(newEvent));
    setSelectedDay(null);

    toggle();
  };

  const handleValidEventSubmitEvent = (e, values) => {
    var newEvent = {};
    newEvent = {
      id: event.id,
      title: values.title,
      classNames: values.category + " text-white",
      start: event.start,
    };
    //first, remove array item, which we want to edit
    let filteredArray = calendarEvents.filter(
      item => item.id + "" !== event.id + ""
    );

    //then concat update item details
    let NewArray = filteredArray.concat(newEvent);

    //store to state
    setCalendarEvents(NewArray);
    setEvent(null);
    setSelectedDay(null);

    toggle1();
  };

  const handleValidEventSubmitcategory = (e, values) => {
    var newEvent = {};

    newEvent = {
      id: calendarEvents.length + 1,
      title: values["title_category"],
      type: values.event_category,
    };
    // categories.concat(newEvent);
    setCategories(categories.concat(newEvent));

    togglecategory();
  };

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const draggedEl = event.draggedEl;

    var newEvent = {
      id: calendarEvents.length + 1,
      title: draggedEl.innerText,
      start: event.date,
      className: draggedEl.getAttribute("data-type") + " text-white",
    };

    // save new event
    setCalendarEvents(calendarEvents.concat(newEvent));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Calendar" />

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-lg-12">
                      {eventList.length && (
                        <FullCalendar
                          ref={calendarComponentRef}
                          defaultView="dayGridMonth"
                          plugins={[
                            BootstrapTheme,
                            dayGridPlugin,
                            interactionPlugin,
                          ]}
                          slotDuration={"00:15:00"}
                          minTime={"08:00:00"}
                          maxTime={"19:00:00"}
                          handleWindowResize={true}
                          themeSystem="bootstrap"
                          header={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,dayGridWeek,dayGridDay",
                          }}
                          events={calendarEvents}
                          editable={true}
                          droppable={true}
                          eventLimit={true}
                          selectable={true}
                          dateClick={handleDateClick}
                          eventClick={handleEventClick}
                          drop={onDrop}
                          id="calendar"
                        />
                      )}

                      {/* New event modal */}
                      <Modal
                        isOpen={addModal}
                        toggle={() => toggle()}
                        className=""
                        backdrop="static"
                      >
                        <ModalHeader
                          toggle={() => {
                            setAddModal(false);
                          }}
                          tag="h4"
                        >
                          Add Event
                        </ModalHeader>
                        <ModalBody>
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <label>Title</label>
                                    <input
                                      type="text"
                                      name="title"
                                      value={values.title}
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />

                                    {errors.title && touched.title ? (
                                      <small className="text-danger fs-6">
                                        {errors.title}
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <input
                                      type="hidden"
                                      name="uid"
                                      value={values.uid}
                                     
                                    />


                                <div className="row">
                                  <div className="col-12">
                                    <label>From Date</label>

                                    <input
                                      type="date"
                                      name="from_date"
                                      value={values.from_date}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className="form-control"
                                    />

                                    {errors.from_date && touched.from_date ? (
                                      <small className="text-danger fs-6">
                                        Please enter the from date
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <label>To Date</label>

                                    <input
                                      type="date"
                                      name="to_date"
                                      className="form-control"
                                      value={values.to_date}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />

                                    {errors.to_date && touched.to_date ? (
                                      <small className="text-danger fs-6">
                                        Please enter the to date
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button className="btn btn-primary mt-3">
                              Submit
                            </button>
                          </form>
                        </ModalBody>
                      </Modal>

                      {/* edit event modal */}
                      <Modal
                        isOpen={editModal}
                        toggle={() => toggle1()}
                        className=""
                        backdrop="static"
                      >
                        <ModalHeader
                          toggle={() => {
                            setEditModal(false);
                          }}
                          tag="h4"
                        >
                          Edit Event
                        </ModalHeader>
                     
                        <ModalBody>
                          <form onSubmit={editSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <label>Title</label>
                                    <input
                                      type="text"
                                      name="title"
                                      onChange={titleHandler}
                                      value={title}
                                      className="form-control"
                                    />
                                  </div>
                                </div>

                           

                                <div className="row">
                                  <div className="col-12">
                                    <label>From Date</label>

                                    <input
                                      type="date"
                                      name="from_date"
                                      onChange={startHandler}
                                      value={startDate}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <label>To Date</label>

                                    <input
                                      type="date"
                                      name="to_date"
                                      className="form-control"
                                      onChange={endHandler}
                                      value={endDate}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button className="btn btn-primary mt-3">
                              Submit
                            </button>
                          </form>

                          <div className="row">
                            <div className="col-12">
                              <button
                                className="btn btn-danger d-block col-2  mt-n4 ms-auto"
                                onClick={() => {
                                  deleteEventHandler(eventId);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
