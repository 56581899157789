import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_TICKET_DETAIL_SUCCESS,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  ADD_ATTACHMENT,
  ADD_ATTACHMENT_SUCCESS,
  FETCH_SINGLE_PROJECT,
  DELETE_ATTACHMENT,
  ADD_TICKET,
  ADD_TICKET_SUCCESS,
  FETCH_TICKETS,
  GET_TICKETS_SUCCESS,
  DELETE_TICKET,
  DELETE_TICKET_SUCCESS,
  EDIT_TICKET,
  GET_TICKET_DETAIL,
  DELETE_ATTACHMENT_TICKET,
  ADD_ATTACHMENT_TICKET,
  ADD_COMMENT,
  FETCH_COMMENT,
  GET_TICKET_COMMENT_SUCCESS,
  DELETE_COMMENT_BY_INDEX,
  EDIT_COMMENT_HANDLER,
  DELINK_TICKET,
  GET_ICONS,
  GET_FETCH_ICON_SUCCESS,
  ADD_NEW_LINK,
  FETCH_SUB_TICKETS,
  FETCH_SUB_TICKETS_SUCCESS,
  FETCH_NEW_DETAILS,
  UPDATE_STATUS,
  GET_GRAPH_DATA_DAILY,
  GET_GRAPH_DATA_DAILY_SUCCESS,
  GET_GRAPH_DATA_WEEKLY,
  GET_GRAPH_DATA_WEEKLY_SUCCESS,
  GET_TICKET_OWNER_GRAPH,
  GET_TICKET_OWNER_GRAPH_SUCCESS,
  UPDATE_STATUS_KANBAN_BOARD,
  ADD_EVENT,
  FETCH_EVENTS,
  FETCH_EVENTS_SUCCESS,
  EDIT_EVENT_SUBMIT,
  DELETE_ADDED_EVENT,
  FETCH_SINGLE_EVENT,
  FETCH_SINGLE_EVENT_SUCCESS,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PASSWORD,
  GET_USER_DETAIL_SUCCESS,
  FETCH_USER_INFORMATION,
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUCCESS,
  GET_TICKET_COMMENT_FAIL
} from "./actionTypes"

export const getProjects = () => ({
  type: GET_PROJECTS,
})


export const getIcons = (var1,var2) => ({
  type: GET_ICONS,
  var1,
  var2
})


export const AddTicket = (data,projectId) => {
  return {
      type: ADD_TICKET,
      payload:  data,
      projectId:  projectId,

      
  }
}

export const EditCommentHandler = (data,ticketId) => {
  return {
      type: EDIT_COMMENT_HANDLER,
      payload:  data,
      ticketId:  ticketId,

      
  }
}




export const EditTicket = (data,projectId) => {
  return {
      type: EDIT_TICKET,
      payload:  data,
      projectId:  projectId,

  }
}


export const fetchNewDetails = (data,ticketId) => {
  return {
      type: FETCH_NEW_DETAILS,
      payload:  data,
      ticketId:  ticketId,

  }
}



export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})


export const getFetchIconSuccess = res => ({
  type: GET_FETCH_ICON_SUCCESS,
  payload: res,
})






export const deleteTicket = ticketId => ({
  type: DELETE_TICKET,
  id: ticketId,
})


export const getTicketSuccess = tickets => ({
  type: GET_TICKETS_SUCCESS,
  payload: tickets,
})



export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})


export const fetchAllUserSuccess = users => ({
  type: FETCH_USER_SUCCESS,
  payload: users,

})


export const fetchAllCustomerSuccess = customer => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload: customer,

})


export const getProjectDetail = (projectId) => ({
  type: GET_PROJECT_DETAIL,
  projectId,
})

export const getGraphDataDaily = (projectId) => ({
  type: GET_GRAPH_DATA_DAILY,
  projectId,
})


export const getGraphDataWeekly = (projectId) => ({
  type: GET_GRAPH_DATA_WEEKLY,
  projectId,
})

export const getTicketOwnerGraph = (projectId) => ({
  type: GET_TICKET_OWNER_GRAPH,
  projectId,
})






export const getTicketDetail = (ticketId) => ({
  type: GET_TICKET_DETAIL,
  ticketId,
})





export const addAttachment = (data,id) => ({
  type: ADD_ATTACHMENT,
  payload: data,
  id:id
})

export const addAttachmentTicket = (data,id) => ({
  type: ADD_ATTACHMENT_TICKET,
  payload: data,
  id:id
})


export const deleteAttachment = (id,projectId) => ({
  type: DELETE_ATTACHMENT,
  payload: id,
  id:projectId
})


export const deleteAddedEvent = (eventId,member) => ({
  type: DELETE_ADDED_EVENT,
  payload: eventId,
  member:member
})

export const getDashboardData = () => ({
  type: GET_DASHBOARD_DATA,
})


export const fetchDataDashboardSuccess = (data) => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload:data
})








export const deleteAttachmentTicket = (id,ticketId) => ({
  type: DELETE_ATTACHMENT_TICKET,
  payload: id,
  id:ticketId
})


export const getProjectDetailSuccess = projectDetails => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: projectDetails,
})


export const getUserDetailSuccess = userDetail => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: userDetail,
})



export const getGraphDataDailySuccess = graphDetails => ({
  type: GET_GRAPH_DATA_DAILY_SUCCESS,
  payload: graphDetails,
})

export const getGraphDataWeeklySuccess = graphDetails => ({
  type: GET_GRAPH_DATA_WEEKLY_SUCCESS,
  payload: graphDetails,
})

export const getTicketOwnerGraphSuccess = graphDetails => ({
  type: GET_TICKET_OWNER_GRAPH_SUCCESS,
  payload: graphDetails,
})





export const getTicketDetailSuccess = ticketDetails => ({
  type: GET_TICKET_DETAIL_SUCCESS,
  payload: ticketDetails,
})


export const getCommentDetailSuccess = comments => ({
  type: GET_TICKET_COMMENT_SUCCESS,
  payload: comments,
})

export const getCommentDetailFail = () => ({
  type: GET_TICKET_COMMENT_FAIL,
  payload: [],
})








export const getProjectDetailFail = error => ({
  type: GET_PROJECT_DETAIL_FAIL,
  payload: error,
})

export const addNewProject = (data) => ({
  type: ADD_NEW_PROJECT,
  payload: data,
})


export const fetchUserInformation = (data) => ({
  type: FETCH_USER_INFORMATION,
  payload: data,
})




export const updatePassword = (data) => ({
  type: UPDATE_PASSWORD,
  payload: data,
})






export const updateProfile = (data,uid) => ({
  type: UPDATE_PROFILE,
  payload: data,
  uid: uid,

})






export const updateStatus = (data,ticketId) => ({
  type: UPDATE_STATUS,
  payload: data,
  ticketId:ticketId
})

export const updateStatusKanbanBoard = (data,projectId) => ({
  type: UPDATE_STATUS_KANBAN_BOARD,
  payload: data,
  projectId:projectId
})




export const addNewLink = (data,ticketId) => ({
  type: ADD_NEW_LINK,
  payload: data,
  ticketId
})


export const addAttachmentSuccess = project => ({
  type: ADD_ATTACHMENT_SUCCESS,
  payload: project,
})



export const addProjectSuccess = project => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
})

export const addTicketSuccess = ticket => ({
  type: ADD_TICKET_SUCCESS,
  payload: ticket,
})


export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
})

export const updateProject = (project,projectId) => ({
  type: UPDATE_PROJECT,
  payload: project,
  projectId: projectId
})

export const updateProjectSuccess = project => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
})

export const updateProjectFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
})

export const deleteProject = id => ({
  type: DELETE_PROJECT,
  payload: id,
})

export const deleteCommentByIndex = data => ({
  type: DELETE_COMMENT_BY_INDEX,
  payload: data,
})


export const delink = data => ({
  type: DELINK_TICKET,
  payload: data,
})




export const FetchUser = () => ({
  type: FETCH_USER,
})


export const FetchCustomer = () => ({
  type: FETCH_CUSTOMER,
})


export const getAllEvents = () => ({
  type: FETCH_EVENTS,
})



export const editEventSubmit = (data) => ({
  type: EDIT_EVENT_SUBMIT,
  payload:data
})


export const fetchEventSuccess = (event) => ({
  type: FETCH_EVENTS_SUCCESS,
  payload:event
})





export const FetchSubTickets = (projectId) => ({
  type: FETCH_SUB_TICKETS,
  projectId,
})

export const fetchSubTicketSuccess = (res) => ({
  type: FETCH_SUB_TICKETS_SUCCESS,
  res,
})






export const addComment = (data,ticketId) => ({
  type: ADD_COMMENT,
  data,
  ticketId
})


export const fetchComment = (payload) => ({
  type: FETCH_COMMENT,
  payload
})







export const fetchTicketByProject = (id) => ({
  type: FETCH_TICKETS,
  id:id,
})









export const deleteProjectSuccess = id => ({

  type: DELETE_PROJECT_SUCCESS,
  payload: id,
}

)

export const deleteTicketSuccess = id => ({

  type: DELETE_TICKET_SUCCESS,
  payload: id,
}

)





export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})


export const addEvent = (data) => ({
  type: ADD_EVENT,
  payload: data,
})

export const fetchSingleEvent = (id) => ({
  type: FETCH_SINGLE_EVENT,
  payload: id,
})


export const getFetchEventSuccess = (res) => ({
  type: FETCH_SINGLE_EVENT_SUCCESS,
  payload: res,
})







