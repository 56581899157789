import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_PROJECTS,
  GET_PROJECT_DETAIL,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  FETCH_USER,
  ADD_ATTACHMENT,
  DELETE_ATTACHMENT,
  ADD_TICKET,
  FETCH_TICKETS,
  DELETE_TICKET,
  EDIT_TICKET,
  GET_TICKET_DETAIL,
  DELETE_ATTACHMENT_TICKET,
  ADD_ATTACHMENT_TICKET,
  ADD_COMMENT,
  FETCH_COMMENT,
  DELETE_COMMENT_BY_INDEX,
  EDIT_COMMENT_HANDLER,
  DELINK_TICKET,
  GET_ICONS,
  ADD_NEW_LINK,
  FETCH_SUB_TICKETS,
  FETCH_NEW_DETAILS,
  UPDATE_STATUS,
  GET_GRAPH_DATA_DAILY,
  GET_GRAPH_DATA_WEEKLY,
  GET_TICKET_OWNER_GRAPH,
  UPDATE_STATUS_KANBAN_BOARD,
  ADD_EVENT,
  FETCH_EVENTS,
  EDIT_EVENT_SUBMIT,
  DELETE_ADDED_EVENT,
  FETCH_SINGLE_EVENT,
  GET_DASHBOARD_DATA,
  UPDATE_PROFILE,
  UPDATE_PASSWORD,
  FETCH_USER_INFORMATION,
  FETCH_CUSTOMER,
} from "./actionTypes";
import {
  getProjectsSuccess,
  getProjectsFail,
  getProjectDetailSuccess,
  getProjectDetailFail,
  addProjectFail,
  addProjectSuccess,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
  fetchAllUserSuccess,
  addAttachmentSuccess,
  getProjectDetail,
  getTicketSuccess,
  deleteTicketSuccess,
  getTicketDetailSuccess,
  getCommentDetailSuccess,
  getFetchIconSuccess,
  fetchSubTicketSuccess,
  getGraphDataDailySuccess,
  getGraphDataWeeklySuccess,
  getTicketOwnerGraphSuccess,
  fetchEventSuccess,
  getFetchEventSuccess,
  fetchDataDashboardSuccess,
  getUserDetailSuccess,
  fetchAllCustomerSuccess,
  getCommentDetailFail,
} from "./actions";

import { url } from "store/constant/base_url";

import {
  addNewAttachment,
  addNewAttachmentTicket,
  addNewProject,
  addNewTicket,
  deleteAttachment,
  deleteAttachmentTicket,
  deleteProjectById,
  deleteTicketById,
  editTicket,
  fetchAllProject,
  fetchAllUsers,
  fetchProjectById,
  fetchTicketById,
  fetchTicketByProject,
  updateProject,
  addNewComment,
  onFetchComment,
  deleteCommentByIndex,
  deleteCommentByIndexApi,
  editCommentHandlerApi,
  delinkTicketHandlerApi,
  fetchAllIcons,
  addNewLinkHandler,
  fetchAllSubTickets,
  onUpdateNewStatus,
  fetchGraphDataDaily,
  fetchGraphDataWeekly,
  fetchTicketOwnerGraph,
  onUpdateNewStatusKanban,
  addNewEvent,
  onFetchEvent,
  editEventApi,
  deleteEventApi,
  onFetchSingleEvent,
  fetchDashboardDataApi,
  updateProfileApi,
  updatePasswordApi,
  fetchUserApi,
  fetchAllCustomers,
} from "helpers/custom-api";

const base_url = url + "/project";

function* fetchProjects() {
  try {
    const response = yield call(fetchAllProject);
    yield put(getProjectsSuccess(response));
  } catch (error) {
    yield put(getProjectsFail(error));
  }
}

function* fetchIcons(data) {
  try {
    const response = yield call(fetchAllIcons, data.var1, data.var2);
    yield put(getFetchIconSuccess(response));
  } catch (error) {
    // yield put(getProjectsFail(error));
  }
}

function* fetchProjectDetail(data) {
  try {
    const response = yield call(fetchProjectById, data.projectId);
    yield put(getProjectDetailSuccess(response));
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}


function* fetchUserInformation(data) {


  try {
    const response = yield call(fetchUserApi, data.payload);
    yield put(getUserDetailSuccess(response));
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}


function* updateProfile(data) {
  try {
    const response = yield call(updateProfileApi,data.payload.formData);
    
    try {
      const response = yield call(fetchUserApi, data.uid);
      yield put(getUserDetailSuccess(response));
    } catch (error) {
      yield put(getProjectDetailFail(error));
    }

  } catch (error) {

  }
}




function* getGraphDataDaily(data) {
  try {
    const response = yield call(fetchGraphDataDaily, data.projectId);
    yield put(getGraphDataDailySuccess(response));
  } catch (error) {
    // yield put(getProjectDetailFail(error));
  }
}

function* getGraphDataWeekly(data) {
  try {
    const response = yield call(fetchGraphDataWeekly, data.projectId);
    yield put(getGraphDataWeeklySuccess(response));
  } catch (error) {
    // yield put(getProjectDetailFail(error));
  }
}

function* getTicketOwnerGraph(data) {
  try {
    const response = yield call(fetchTicketOwnerGraph, data.projectId);
    yield put(getTicketOwnerGraphSuccess(response));
  } catch (error) {
    // yield put(getProjectDetailFail(error));
  }
}

function* fetchTicketDetail(data) {
  try {
    const response = yield call(fetchTicketById, data.ticketId);
    yield put(getTicketDetailSuccess(response));
  } catch (error) {
    // yield put(getProjectDetailFail(error));
  }
}

function* delinkTicketHander(data) {
  try {
    const response = yield call(
      delinkTicketHandlerApi,
      data.payload.ticketId,
      data.payload.subTicketId
    );

    try {
      const response = yield call(fetchTicketById, data.payload.ticketId);
      yield put(getTicketDetailSuccess(response));
    } catch (error) {
      // yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    // yield put(deleteProjectFail(error));
  }
}

function* fetchUsers() {
  try {
    const response = yield call(fetchAllUsers);
    yield put(fetchAllUserSuccess(response));
    // history.push('/dashboard')
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}

function* fetchCustomers() {
  try {
    const response = yield call(fetchAllCustomers);
    yield put(fetchAllCustomerSuccess(response));
    // history.push('/dashboard')
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}




function* fetchSubTickets(data) {
  try {
    const response = yield call(fetchAllSubTickets, data.projectId);
    yield put(fetchSubTicketSuccess(response));
    // history.push('/dashboard')
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}

function* onUpdateProject({ payload: project, projectId: projectId }) {
  var history = project.history;

  console.log("projectId", projectId);

  try {
    var response = yield call(updateProject, project.formData);

    yield put(updateProjectSuccess(response));

    try {
      var response2 = yield call(fetchProjectById, projectId);

      yield put(getProjectDetailSuccess(response2));
    } catch (error) {
      yield put(getProjectDetailFail(error));
    }

    history.push("/projects-card");
  } catch (error) {
    yield put(updateProjectFail(error));
  }
}

function* onDeleteProject(data) {
  try {
    const response = yield call(deleteProjectById, data.payload);
    yield put(deleteProjectSuccess(data.payload));
  } catch (error) {
    yield put(deleteProjectFail(error));
  }
}

function* deleteCommentHandler(data) {
  try {
    const response = yield call(
      deleteCommentByIndexApi,
      data.payload.ticketId,
      data.payload.index
    );
    try {
      const response = yield call(onFetchComment, data.payload.ticketId);
      yield put(getCommentDetailSuccess(response));
    } catch (error) {
      // yield put(addProjectFail(error));
    }
  } catch (error) {
     yield put(getCommentDetailFail());
  }
}

function* onDeleteTicket(data) {
  try {
    const response = yield call(deleteTicketById, data.id);
    yield put(deleteTicketSuccess(data.id));
  } catch (error) {
    // yield put(deleteProjectFail(error))
  }
}

function* onAddNewProject(data) {
  var history = data.payload.history;
  try {
    const response = yield call(addNewProject, data.payload.formData);
    yield put(addProjectSuccess(response));
    history.push("/projects-card");
  } catch (error) {
    yield put(addProjectFail(error));
  }
}


function* onUpdatePassword(data) {
  // var history = data.payload.history;
  try {
    const response = yield call(updatePasswordApi, data.payload.formData);
    yield put(addProjectSuccess(response));
    history.push("/projects-card");
  } catch (error) {
    yield put(addProjectFail(error));
  }
}





function* onUpdateStatus(data) {
  try {
    const response = yield call(onUpdateNewStatus, data.payload);
    try {
      const response = yield call(fetchTicketById, data.ticketId);
      yield put(getTicketDetailSuccess(response));
    } catch (error) {}
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onUpdateStatusKanbanBoard(data) {
  try {
    const response = yield call(onUpdateNewStatusKanban, data.payload);
    try {
      const response = yield call(fetchTicketByProject, data.projectId);
      yield put(getTicketSuccess(response));
    } catch (error) {
      yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onAddNewLink(data) {
  try {
    const response = yield call(addNewLinkHandler, data.payload);

    try {
      const response = yield call(fetchTicketById, data.ticketId);
      yield put(getTicketDetailSuccess(response));
    } catch (error) {
      // yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onAddTicket(data) {
  try {
    const response = yield call(addNewTicket, data.payload);

    try {
      const response = yield call(fetchTicketByProject, data.projectId);
      yield put(getTicketSuccess(response));
    } catch (error) {
      yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onEditCommentHandler(data) {
  try {
    const response = yield call(editCommentHandlerApi, data.payload);

    try {
      const response = yield call(onFetchComment, data.ticketId);
      yield put(getCommentDetailSuccess(response));
    } catch (error) {
      // yield put(addProjectFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onEditTicket(data) {
  try {
    const response = yield call(editTicket, data.payload);
    try {
      const response = yield call(fetchTicketByProject, data.projectId);
      yield put(getTicketSuccess(response));
    } catch (error) {
      yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onFetchTicket(data) {
  try {
    const response = yield call(fetchTicketByProject, data.id);
    yield put(getTicketSuccess(response));
  } catch (error) {
    // yield put(getProjectDetailFail(error))
  }
}

function* onAddAttachment(data) {
  try {
    const response = yield call(addNewAttachment, data.payload);
    // yield call(getProjectDetail);

    try {
      const response = yield call(fetchProjectById, data.id);
      yield put(getProjectDetailSuccess(response));
    } catch (error) {
      yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onAddAttachmentTicket(data) {
  try {
    const response = yield call(addNewAttachmentTicket, data.payload);
    try {
      const response = yield call(fetchTicketById, data.id);
      yield put(getTicketDetailSuccess(response));
    } catch (error) {
      // yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onAddComment(data) {
  try {
    const response = yield call(addNewComment, data.data);

    try {
      const response = yield call(onFetchComment, data.ticketId);
      yield put(getCommentDetailSuccess(response));
    } catch (error) {}
  } catch (error) {
    // yield put(addProjectFail(error));
  }
}

function* addEvent(data) {
  try {
    const response = yield call(addNewEvent, data.payload);
    try {
      const response = yield call(onFetchEvent);
      yield put(fetchEventSuccess(response));
    } catch (error) {}
  } catch (error) {
    // yield put(addProjectFail(error));
  }
}

function* editEvent(data) {
  try {
    const response = yield call(editEventApi, data.payload);
    try {
      const response = yield call(onFetchEvent);
      yield put(fetchEventSuccess(response));
    } catch (error) {}
  } catch (error) {
    // yield put(addProjectFail(error));
  }
}

function* fetchEvent() {
  const response = yield call(onFetchEvent);
  try {
    yield put(fetchEventSuccess(response));
  } catch (error) {}
}

function* onDeleteEvent(data) {
  const response = yield call(deleteEventApi, data.payload, data.member);
  try {
    const response = yield call(onFetchEvent);
    yield put(fetchEventSuccess(response));
  } catch (error) {}
}

function* fetchComment(data) {
  try {
    const response = yield call(onFetchComment, data.payload);
    yield put(getCommentDetailSuccess(response));
  } catch (error) {
    yield put(getCommentDetailFail());  }
}

function* singleEvent(data) {
  try {
    const response = yield call(onFetchSingleEvent, data.payload);
    yield put(getFetchEventSuccess(response));
  } catch (error) {
    // yield put(addProjectFail(error));
  }
}

function* fetchDashboardData() {
  try {
    const response = yield call(fetchDashboardDataApi);
    yield put(fetchDataDashboardSuccess(response));
  } catch (error) {
    // yield put(addProjectFail(error));
  }
}







function* onDeleteAttachmentTicket(data) {
  try {
    const response = yield call(deleteAttachmentTicket, data.id, data.payload);
    try {
      const response = yield call(fetchTicketById, data.id);
      yield put(getTicketDetailSuccess(response));
    } catch (error) {
      // yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onDeleteAttachment(data) {
  try {
    const response = yield call(deleteAttachment, data.id, data.payload);
    yield call(getProjectDetail);
    try {
      const response = yield call(fetchProjectById, data.id);
      yield put(getProjectDetailSuccess(response));
    } catch (error) {
      yield put(getProjectDetailFail(error));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* fetchTicketDetails(data) {
  console.log(data);
  const response = yield call(editTicket, data.payload);
  try {
    const response = yield call(fetchTicketById, data.ticketId);
    yield put(getTicketDetailSuccess(response));
  } catch (error) {}
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects);
  yield takeEvery(GET_ICONS, fetchIcons);

  yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail);
  yield takeEvery(FETCH_USER, fetchUsers);
  yield takeEvery(FETCH_CUSTOMER, fetchCustomers);

  yield takeEvery(FETCH_SUB_TICKETS, fetchSubTickets);

  yield takeEvery(ADD_ATTACHMENT, onAddAttachment);
  yield takeEvery(ADD_ATTACHMENT_TICKET, onAddAttachmentTicket);

  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject);
  yield takeEvery(UPDATE_PASSWORD, onUpdatePassword);


  yield takeEvery(UPDATE_STATUS, onUpdateStatus);
  yield takeEvery(UPDATE_STATUS_KANBAN_BOARD, onUpdateStatusKanbanBoard);

  yield takeEvery(ADD_NEW_LINK, onAddNewLink);

  yield takeEvery(DELETE_ATTACHMENT, onDeleteAttachment);
  yield takeEvery(DELETE_ADDED_EVENT, onDeleteEvent);

  yield takeEvery(DELETE_ATTACHMENT_TICKET, onDeleteAttachmentTicket);
  yield takeEvery(FETCH_TICKETS, onFetchTicket);
  yield takeEvery(DELETE_TICKET, onDeleteTicket);

  yield takeEvery(UPDATE_PROJECT, onUpdateProject);
  yield takeEvery(DELETE_PROJECT, onDeleteProject);
  yield takeEvery(ADD_TICKET, onAddTicket);
  yield takeEvery(EDIT_COMMENT_HANDLER, onEditCommentHandler);

  yield takeEvery(EDIT_TICKET, onEditTicket);
  yield takeEvery(GET_TICKET_DETAIL, fetchTicketDetail);
  yield takeEvery(ADD_COMMENT, onAddComment);
  yield takeEvery(FETCH_COMMENT, fetchComment);
  yield takeEvery(DELETE_COMMENT_BY_INDEX, deleteCommentHandler);

  yield takeEvery(DELINK_TICKET, delinkTicketHander);
  yield takeEvery(FETCH_NEW_DETAILS, fetchTicketDetails);

  yield takeEvery(GET_GRAPH_DATA_DAILY, getGraphDataDaily);
  yield takeEvery(GET_GRAPH_DATA_WEEKLY, getGraphDataWeekly);
  yield takeEvery(GET_TICKET_OWNER_GRAPH, getTicketOwnerGraph);
  yield takeEvery(ADD_EVENT, addEvent);
  yield takeEvery(FETCH_EVENTS, fetchEvent);
  yield takeEvery(EDIT_EVENT_SUBMIT, editEvent);
  yield takeEvery(FETCH_SINGLE_EVENT, singleEvent);
  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData);
  yield takeEvery(UPDATE_PROFILE, updateProfile);
  yield takeEvery(FETCH_USER_INFORMATION, fetchUserInformation);



  
  


  
}

export default projectsSaga;
