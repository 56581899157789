import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { loginNewUser } from "helpers/custom-api";



import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();



function* loginUser({ payload: { user, history } }) {
  
  try {
    const response = yield call(loginNewUser, {username:user.username,password:user.password})

    var tokenId = response.data.token
    var uid = response.data.uid
    var image = response.data.image
    var first_name = response.data.first_name
    var last_name = response.data.last_name

   
    localStorage.setItem('token',tokenId)
    localStorage.setItem('uid',uid)
    localStorage.setItem('image',image)
    localStorage.setItem('first_name',first_name)
    localStorage.setItem('last_name',last_name)

    yield put(loginSuccess())


    history.push("/dashboard");
  } catch (error) {
    yield put(apiError('Invalid user Credentials'));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);

  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
