import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { map, get, isEmpty } from "lodash";
import {
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Button,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import img1 from "../../../assets/images/companies/img-1.png";
import { url } from "store/constant/base_url";
import moment from "moment";
import classes from "../projects.module.css";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import calendar from "../../../../src/assets/images/calendar-icon.png";
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  addNewLink,
  delink,
  FetchSubTickets,
  updateStatus,
} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import Icon from "./icon";
import makeAnimated from "react-select/animated";
import EditTicketModal from "./editTicketModal";

const ProjectDetail = props => {
  const dispatch = useDispatch();
  const base_url = url;
  const project = props.project;
  const variable = props.variable;
  const ticket = props.ticket ? props.ticket : [];
  const ProjectName = props.param;
  const paramId = props.paramId;
  const graphDetailDaily = props.graphDetailDailyData;
  const graphDetailWeekly = props.graphDetailsWeeklyData;

  const [link, setLink] = useState([]);
  const [selectToggle, setSelectToggle] = useState(false);

  const animatedComponents = makeAnimated();

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [estimateTime, setEstimateTime] = useState("");



  const closeModalHandler = () => {
    setModal(false);
  };

  const showModalHandler = () => {
    setModal(true);
  };

  const submitEstimateTimeHandler = ()=>{
    
    let formData = new FormData();
    formData.append("ticket_id", paramId);
    formData.append("field", "effort_estimate");
    formData.append("value", estimateTime);

    dispatch(updateStatus(formData, paramId));

    setEstimateTime('')
    setModal2(false)

  }

  const changeStatusHandler = e => {
    let formData = new FormData();
    formData.append("ticket_id", paramId);

    formData.append("field", "status");
    formData.append("value", e);

    dispatch(updateStatus(formData, paramId));
  };

  useEffect(() => {
    if (variable == "ticket") {
      dispatch(FetchSubTickets(ticket.project));
    }
  }, []);

  const userList = useSelector(state => state.projects.subTasks);

  userList.forEach(object => {
    object.label = object.ticket_id;
    object.value = object.ticket_id;
  });

  const subTaskList = userList.filter(ele => {
    return ele.ticket_id != paramId;
  });

  const onLikChange = event => {
    setLink(event);
  };

  const onCreateLink = event => {
    var filterArray = [];
    link.forEach(element => {
      filterArray.push(element.label);
    });

    event.preventDefault();
    setSelectToggle(false);

    let formData = new FormData();

    formData.append("parent_ticket_id", paramId);
    formData.append("child_tickets_list", JSON.stringify(filterArray));

    dispatch(addNewLink(formData, ticket.ticket_id));
  };

  const delinkTicket = (ticketId, subTicketId) => {
    var data = {
      ticketId: ticketId,
      subTicketId: subTicketId,
    };

    swal({
      text: "Are you sure you want to delink!",
      icon: "warning",
      buttons: true,
    }).then(willDelete => {
      if (willDelete) {
        dispatch(delink(data));
        swal(" Your subticket has been delinked!", {
          icon: "success",
          timer: 2000,
        });
      }
    });
  };

  const openModal = () => {
    setModal2(!modal2);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-flex">
            {project?.logo && (
              <img
                src={`${base_url}/user/fetch_profile_image/${project?.logo.value}`}
                alt=""
                className="avatar-sm me-4"
              />
            )}
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15">{project?.title}</h5>
              {project?.short_description && (
                <small className={classes.overFlowTextLine1}>
                  {project?.short_description}
                </small>
              )}
            </div>
          </div>

          {variable == "project" && (
            <>
              <h5 className="font-size-15 mt-4">Project Details :</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: project.project_details,
                }}
              />
            </>
          )}

          {variable == "project" && (
            <Row className="task-dates">
              <Col sm="2" xs="6">
                <div className="mt-4">
                  <h5 className="font-size-14">
                    <i className="bx bx-calendar me-1 text-primary" /> Start
                    Date
                  </h5>
                  <p className="text-muted mb-0">
                    {moment(new Date(project?.start_date)).format("DD MMM, YY")}
                  </p>
                </div>
              </Col>

              <Col sm="2" xs="6">
                <div className="mt-4">
                  <h5 className="font-size-14">
                    <i className="bx bx-calendar-check me-1 text-primary" /> Due
                    Date
                  </h5>
                  {moment(new Date(project.end_date)).format("DD MMM, YY")}
                </div>
              </Col>

              <Col sm="4" xs="6">
                <div className="mt-4">
                  <h5 className="font-size-14">
                    <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                    Project Leader
                  </h5>

                  <ul>
                    {project.manager.map((item, key) => (
                      <li key={key}>
                        {" "}
                        {item.label} ({item.uid})
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>

              <Col sm="4" xs="6">
                <div className="mt-4">
                  <h5 className="font-size-14">
                    <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                    Team Member
                  </h5>

                  <ul>
                    {project.team_members.map((item, key) => (
                      <li key={key}>
                        {" "}
                        {item.label} ({item.uid})
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          )}

          {variable == "ticket" && (
            <>
              <small className="d-flex fs-6">
                <Link to={"/projects-card"} className="me-1">Projects</Link> /{" "}
                <Link
                  to={`/view-tickets/${ProjectName}/${ticket.project}`}
                  className="mx-1"
                >
                  {ProjectName}
                </Link>
                /{" "}
                <Link
                  className="d-flex mx-1"
                  style={{ position: "relative", top: "-3px" }}
                >
                  {" "}
                  {/* <Icon type={ticket.type} typeValue={"type"}></Icon>{" "} */}
                  <span style={{ top: "2px", position: "relative" }}>
                    {ticket.ticket_id}
                  </span>
                </Link>
              </small>
              <h5 className="font-size-15 mt-2">{ticket.title}</h5>
              <button
                className="btn btn-sm btn-secondary"
                onClick={showModalHandler}
              >
                <i className="fas fa-edit me-1"></i>Edit
              </button>
              <button
                className="btn btn-secondary text-white btn-sm ms-2 me-2 "
                onClick={() => {
                  setSelectToggle(!selectToggle);
                }}
              >
                Link Tickets
              </button>
              <button
                className={`${ticket.status == "Open" && classes.none} ${
                  ticket.status != "Open" &&
                  " btn btn-sm btn-secondary text-white ms-1"
                }`}
                onClick={() => {
                  changeStatusHandler("open");
                }}
              >
                Open
              </button>
              <button
                className={`${
                  ticket.status == "Under Review" && classes.none
                } ${
                  ticket.status != "Under Review" &&
                  " btn btn-sm btn-secondary text-white ms-1"
                }`}
                onClick={() => {
                  changeStatusHandler("Under Review");
                }}
              >
                Under Review
              </button>

              <button
                className={`${
                  ticket.status == "In Progress" && classes.none
                }  ${
                  ticket.status != "In Progress" &&
                  " btn btn-sm btn-secondary text-white ms-1"
                }`}
                onClick={() => {
                  changeStatusHandler("In Progress");
                }}
              >
                In progress
              </button>

              <button
                className=
                
                  " btn btn-sm btn-secondary text-white ms-1"
                
       
                onClick={openModal}
              >
                Resolved
              </button>

              <button
                className={`${ticket.status == "Completed" && classes.none} ${
                  ticket.status != "Completed" &&
                  " btn btn-sm btn-secondary text-white ms-1"
                }`}
                onClick={() => {
                  changeStatusHandler("Completed");
                }}
              >
                Completed
              </button>

              {selectToggle && (
                <Form onSubmit={onCreateLink}>
                  <FormGroup className="mb-4" row>
                    <Row>
                      <Col lg="12">
                        <label className="col-form-label col-lg-2">
                          Sub Tickets
                        </label>
                      </Col>

                      <Col lg="4">
                        <Select
                          onChange={onLikChange}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={subTaskList}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <Button type="submit" color="primary" onClick={() => {}}>
                    Submit
                  </Button>
                </Form>
              )}
            </>
          )}

          {variable == "ticket" && (
            <Row className="task-dates">
              <Col sm="4" xs="6">
                <div className="mt-4">
                  <h5 className="font-size-14">
                    Type <br></br>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <Icon type={ticket.type} typeValue={"type"}></Icon>

                        <div style={{ minWidth: "60px " }}>
                          <small className="font-size-12  mt-2 ms-1">
                            {ticket.type}
                          </small>
                        </div>
                      </div>
                    </div>
                  </h5>
                </div>
              </Col>

              <Col sm="4" xs="6">
                <div className="mt-4">
                  <h5 className="font-size-14">
                    Status <br></br>
                    <div className="row">
                      <div className="col-12 d-flex">
                        {/* <div className="">
                          <i
                            className="fa fa-arrow-down me-2"
                            aria-hidden="true"
                          ></i>
                        </div> */}
                        <div style={{ width: "100px " }}>
                          <small className="font-size-12  mt-2 ms-1">
                            <span
                              className={
                                ticket.status == "In Progress"
                                  ? "font-size-12 badge-soft-primary ms-n2 mt-1 badge bg-warning rounded-pill"
                                  : ticket.status == "Completed"
                                  ? "font-size-12 badge-soft-success ms-n2 mt-1 badge bg-success rounded-pill"
                                  : ticket.status == "Under Review"
                                  ? "font-size-12 badge-soft-info badge ms-n2 mt-1 bg-primary rounded-pill"
                                  : "font-size-12 badge-soft-secondary  ms-n2 mt-1 badge bg-secondary rounded-pill"
                              }
                            >
                              {" "}
                              {ticket.status}
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </h5>
                </div>
              </Col>

              <Col sm="4" xs="6">
                <div className="mt-4">
                  <h5 className="font-size-14">
                    Priority <br></br>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <Icon
                          type={ticket.priority}
                          typeValue={"priority"}
                        ></Icon>
                        <div style={{ minWidth: "60px " }}>
                          <small className="font-size-12  mt-2 ms-1">
                            {ticket.priority}
                          </small>
                        </div>
                      </div>
                    </div>
                  </h5>
                </div>
              </Col>
            </Row>
          )}

          {variable == "ticket" && (
            <Row className="task-dates mt-3">
              <Col sm="4" xs="6">
                <h5 className="font-size-14">Assignee</h5>
                {ticket.assignee.map((member, k) => (
                  <div key={k} className="row col-12">
                    {/* <div className="col-1">
                      <img
                        src={`${base_url}/user/fetch_profile_image/${member.image}`}
                        className="rounded-circle "
                        height="25"
                        width="25"
                        alt=""
                      />
                    </div> */}

                    <div className="col-10">
                      <small className="font-size-12  mt-2 ms-1">
                        <Link to="" className="text-dark">
                          {member.label}
                        </Link>
                      </small>
                    </div>
                  </div>
                ))}
              </Col>

              <Col sm="4" xs="6">
                <h5 className="font-size-14">Reporter</h5>
                {ticket.reporter.map((member, k) => (
                  <div key={k} className="row col-12">
                    {/* <div className="col-1">
                      <img
                        src={`${base_url}/user/fetch_profile_image/${member.image}`}
                        className="rounded-circle avatar-xs"
                        alt=""
                        height="25"
                        width="25"
                      />
                    </div> */}

                    <div className="col-10">
                      <small className="font-size-12  mt-2 ms-1">
                        <Link to="" className="text-dark">
                          {member.label}
                        </Link>
                      </small>
                    </div>
                  </div>
                ))}
              </Col>

              <Col sm="4" xs="6" className="mt-n4">
                <div className="mt-4">
                  <h5 className="font-size-14 mb-1">
                    Created Date <br></br>
                  </h5>
                  <i className="bx bx-calendar-check me-1 text-primary" />
                  {/* <i className="fa fa-calendar "></i> */}
                  {/* <img src={calendar} height="20" width="20" /> */}
                  <small className="mt-2 font-size-12">
                    {ticket.created_timestamp.substring(0, 25)}
                  </small>
                </div>
              </Col>
            </Row>
          )}

          {variable == "ticket" && (
            <React.Fragment>
              <h5 className="font-size-15 mt-4">Ticket Details :</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: ticket.description,
                }}
              />
            </React.Fragment>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={modal2} toggle={openModal}>
  
        <ModalBody>
     
            <label className="form-label">Estimate time</label>
            <input
              type="text"
              placeholder="Estimate time..."
              onChange={data => {
                setEstimateTime(data.target.value);
              }}
              className="form-control"
            />

            <button
          
              onClick={submitEstimateTimeHandler}
              className="btn mt-3 btn-primary"
            >
              Submit
            </button>
        
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>

      {!isEmpty(ticket.sub_tickets) && (
        <Card>
          <CardBody>
            <h5 className="font-size-15">Sub Tasks :</h5>

            <div>
              {ticket.sub_tickets?.map((item, i) => (
                <div key={i}>
                  <div className=" row col-12">
                    <div className="col-2">
                      <a
                        href={
                          "/ticket-overview/" +
                          ProjectName +
                          "/" +
                          item.ticket_id
                        }
                      >
                        <p className="text-truncate font-size-13 fw-bold d-flex">
                          <Icon type={item.type} typeValue={"type"}></Icon>

                          {item.ticket_id}
                        </p>
                      </a>
                    </div>
                    <div className="col-6">
                      <p className="text-truncate font-size-13 ">
                        {item.title}
                      </p>
                    </div>

                    <div className="col-2">
                      <p
                        className={
                          item.status == "In Progress"
                            ? "font-size-13 badge-soft-warning badge bg-warning rounded-pill align-self-center"
                            : item.status == "Completed"
                            ? "font-size-13 badge-soft-success badge bg-success rounded-pill align-self-center"
                            : item.status == "Under Review"
                            ? "font-size-13 badge-soft-primary badge bg-primary rounded-pill align-self-center"
                            : "font-size-13 badge-soft-secondary badge bg-secondary rounded-pill align-self-center"
                        }
                      >
                        {item.status}
                      </p>
                    </div>

                    <div className="col-1">
                      <p className="text-truncate font-size-13 d-flex">
                        <Icon
                          type={item.priority}
                          typeValue={"priority"}
                        ></Icon>

                        {/* {item.priority} */}
                      </p>
                    </div>

                    <div className="col-1">
                      <p>
                        <i
                          className="mdi mdi-delete ms-4 font-size-13 text-primary font-size-16"
                          onClick={() => {
                            delinkTicket(ticket.ticket_id, item.ticket_id);
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  <hr className="mt-n2"></hr>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      )}
      {modal && (
        <EditTicketModal
          ticketData={ticket}
          projectId={props.paramId}
          closeModal={closeModalHandler}
        />
      )}
    </React.Fragment>
  );
};

export default ProjectDetail;
