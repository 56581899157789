import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import swal from "sweetalert";
import Select from "react-select";
import classnames from "classnames";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import AddTicketModal from "./addTicketModal";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Import Breadcrumb

import {
  deleteTicket,
  EditTicket,
  fetchTicketByProject,
  FetchUser,
  updateStatus,
  updateStatusKanbanBoard,
} from "store/actions";
import makeAnimated from "react-select/animated";

import DatatableTables from "../../Tables/DatatableTables";
import { url } from "store/constant/base_url";
import moment from "moment";
import Icon from "./icon";

const ShowTicketTable = () => {
  const params = useParams();

  const recorderControls = useAudioRecorder();
  const addAudioElement = blob => {
    console.log(blob);
    // const url = URL.createObjectURL(blob);
    // const audio = document.getElementById("audioDiv");
    // audio.src = url;
    // audio.controls = true;
    // document.body.appendChild(audio);
  };

  const base_url = url;

  const animatedComponents = makeAnimated();

  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const [attachments, setAttachments] = useState([]);

  const [ticket_status, setStatus] = useState("");
  const [reporter, setReporter] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [priority, setPriority] = useState("");
  const [skills, setSkills] = useState("");
  const [time, setTime] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [ticket, setTicket] = useState("");

  const dispatch = useDispatch();
  const ticketList = useSelector(state => state.projects.tickets);

  const userList = useSelector(state => state.projects.users);

  const [activeTab1, setActiveTabs] = useState("1");

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setActiveTabs(tab);
    }
  };

  useEffect(() => {
    dispatch(FetchUser());

    dispatch(fetchTicketByProject(params.id));
  }, []);

  userList.forEach(object => {
    object.label = object.first_name + " " + object.last_name;
    object.value = object.uid;
  });

  const result = userList.map(
    ({ _id, phone, email, user_image, first_name, last_name, ...rest }) => ({
      ...rest,
    })
  );

  const addTicketHandler = event => {
    setModal(true);
  };

  const closeAddModalHandler = () => {
    setModal(false);
  };

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setAttachments(files);
  };

  const Open = ticketList.filter(ele => {
    return ele.status == "Open";
  });

  const InProgress = ticketList.filter(ele => {
    return ele.status == "In Progress";
  });

  const UnderReview = ticketList.filter(ele => {
    return ele.status == "Under Review";
  });

  const Completed = ticketList.filter(ele => {
    return ele.status == "Completed";
  });

  const onDelete = event => {
    swal({
      text: "Are you sure you want to delete!",
      icon: "warning",
      buttons: true,
    }).then(willDelete => {
      if (willDelete) {
        dispatch(deleteTicket(event.ticket_id));
        swal("Poof! Your ticket has been deleted!", {
          icon: "success",
          timer: 1500,
        });
      }
    });
  };

  const managerOptions = result;
  const teamMembers = result;

  const columns = [
    {
      dataField: "ticket_id",
      isDummyField: true,
      text: "Ticket Id",
      formatter: (cellContent, data) => (
        <div className="row col-12">
          <Icon
            type={data.type}
            typeValue={"type"}
            className="col-3 d-block"
          ></Icon>{" "}
          <span style={{ fontWeight: "600" }} className="col-3 d-block">
            {data.ticket_id}
          </span>
        </div>
      ),
    },
    { dataField: "title", text: "Title" },
    {
      dataField: "created_date",
      isDummyField: true,
      text: "Date",
      formatter: (cellContent, data) => (
        <span>{moment(new Date(data.created_date)).format("DD MMM, YY")}</span>
      ),
    },
    {
      dataField: "status",
      isDummyField: true,
      text: "Status",
      formatter: (cellContent, data) => (
        <>
          <div className="d-flex gap-3">
            <span
              className={
                data.status == "In Progress"
                  ? "font-size-12 badge-soft-warning badge bg-warning rounded-pill"
                  : data.status == "Completed"
                  ? "font-size-12 badge-soft-success badge bg-success rounded-pill"
                  : data.status == "Under Review"
                  ? "font-size-12 badge-soft-primary badge bg-primary rounded-pill"
                  : "font-size-12 badge-soft-secondary badge bg-secondary rounded-pill"
              }
            >
              {data.status}
            </span>
          </div>
        </>
      ),
    },

    {
      dataField: "priority",
      isDummyField: true,
      text: "Priority",
      formatter: (cellContent, data) => (
        <>
          <div className="d-flex gap-3">
            <Icon type={data.priority} typeValue={"priority"}></Icon>
          </div>
        </>
      ),
    },

    {
      dataField: "assignee",
      isDummyField: true,
      text: "Assignee",
      formatter: (cellContent, data) => (
        <>
          <div className="d-flex gap-3">
            {data.assignee.map((item, index) => (
              <div className="user" key={index}>
                {/* <Link
                      to="#"
                      className="d-inline-block"
                      id={"member" + item.uid}
                      key={"_item_" + index}
                    > */}
                {/* <img
                        src={`${base_url}/user/fetch_profile_image/${item.image}`}
                        className="rounded-circle avatar-xs"
                        alt=""
                      /> */}
                <i className="fas fa-user-alt"></i>&nbsp; {item.uid}
                {/* </Link> */}
              </div>
            ))}
          </div>
        </>
      ),
    },
    {
      dataField: "view_deatils",
      isDummyField: true,
      text: "View Details",
      formatter: (cellContent, ticketList) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={
                "/ticket-overview/" + params.name + "/" + ticketList.ticket_id
              }
            >
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded btn btn-primary"
              >
                View Details
              </Button>
            </Link>
          </div>
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, ticketList) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() => viewTicket(ticketList)}
              />
            </Link>
            <Link to="#" className="text-danger">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => onDelete(ticketList)}
              />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const onSetReporter = event => {
    setReporter(event);
  };

  const onsetAssignee = event => {
    setAssignee(event);
  };

  const onStatusValue = event => {
    setStatus(event.target.value);
  };

  const onTypeValue = event => {
    setType(event.target.value);
  };

  const onPriorityValue = event => {
    setPriority(event.target.value);
  };

  const onEstimateTime = event => {
    setTime(event.target.value);
  };

  const onSkillsValue = event => {
    setSkills(event.target.value);
  };

  const onTitleValue = event => {
    setTitle(event.target.value);
  };

  const closeModalHandler = () => {
    setModal(false);
  };
  const projectDetail = useSelector(state => state.projects.projectDetail);

  const onEditTicket = event => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("assignee", JSON.stringify(assignee));
    formData.append("title", title);
    formData.append("description", description);
    formData.append("reporter", JSON.stringify(reporter));
    formData.append("original_estimate", time);
    formData.append("project", params.id);
    formData.append("status", ticket_status);
    formData.append("skills", skills);
    formData.append("type", type);
    formData.append("priority", priority);
    formData.append("ticket_id", ticket);
    dispatch(EditTicket(formData, params.id));
    setEditModal(false);
  };

  const viewTicket = event => {
    setEditModal(true);
    var SingleTicketData = ticketList.filter(
      id => id.ticket_id == event.ticket_id
    );

    setTitle(SingleTicketData[0]["title"]);
    setDescription(SingleTicketData[0]["description"]);
    setStatus(SingleTicketData[0]["status"]);
    setType(SingleTicketData[0]["type"]);
    setPriority(SingleTicketData[0]["priority"]);
    setAssignee(SingleTicketData[0]["assignee"]);
    setReporter(SingleTicketData[0]["reporter"]);
    setTicket(SingleTicketData[0]["ticket_id"]);
    setTime(SingleTicketData[0]["original_estimate"]);
    setSkills(SingleTicketData[0]["skills"]);
  };

  const changeStatusHandler = (e, ticketId) => {
    let formData = new FormData();
    formData.append("ticket_id", ticketId);

    formData.append("field", "status");
    formData.append("value", e);

    dispatch(updateStatusKanbanBoard(formData, params.id));
  };

  // for edit

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified mb-3">
                    <NavItem className="">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "1",
                        })}
                        onClick={() => {
                          toggle1("1");
                        }}
                      >
                        Ticket Table
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "2",
                        })}
                        onClick={() => {
                          toggle1("2");
                        }}
                      >
                        Kanban Board
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {activeTab1 == 1 && (
                    <React.Fragment>
                      {/* <div className="row" id="audioDiv">
                       <AudioRecorder 
                          onRecordingComplete={(blob) => addAudioElement(blob)}
                          recorderControls={recorderControls}
                        />
                        <Button onClick={recorderControls.stopRecording}>Stop recording</Button>
                       </div> */}
                      <DatatableTables
                        items={ticketList}
                        columns={columns}
                        addButtonHandler={addTicketHandler}
                        showAddButton={true}
                        addButtonLabel="Add New Ticket"
                      />
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>

              {activeTab1 == 2 && (
                <div className="d-flex justify-content-between row">
                  <div className="col-3 ">
                    <div className="card">
                      <h4 className="card m-2 p-2">Open </h4>

                      <div
                        className="card-body"
                        style={{
                          minHeight: "5em",
                          maxHeight: "58em",
                          overflow: "auto",
                        }}
                      >
                        {Open.length > 0 &&
                          Open.map((item, key) => (
                            <div className="card" key={key}>
                              <div className="card-body">
                                <div className="row">
                                  <Icon
                                    type={item.type}
                                    typeValue={"type"}
                                    className="col-1 d-block"
                                  ></Icon>{" "}
                                  <span
                                    style={{ fontWeight: "600" }}
                                    className="col-9 d-block"
                                  >
                                    <a
                                      href={`/ticket-overview/${params.name}/${item.ticket_id}`}
                                    >
                                      {" "}
                                      {item.ticket_id}
                                    </a>
                                  </span>
                                  <UncontrolledDropdown className="col-1">
                                    <DropdownToggle
                                      href="#"
                                      className="arrow-none"
                                      tag="i"
                                    >
                                      <i
                                        className="mdi mdi-dots-vertical m-0 text-muted h5"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <Link>
                                        <DropdownItem
                                          onClick={() => {
                                            changeStatusHandler(
                                              "In Progress",
                                              item.ticket_id
                                            );
                                          }}
                                        >
                                          In Progress
                                        </DropdownItem>
                                      </Link>
                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "Under Review",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        Under Review
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "Completed",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        Completed
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>

                                <div className="row mt-2">
                                  <h6 className="col-11">{item.title} </h6>
                                </div>

                                <Col xl="12 row mb-1 mt-2">
                                  <div className="d-flex  col-4 flex-wrap">
                                    <p
                                      className={
                                        item.priority == "High"
                                          ? "font-size-12 badge-soft-warning badge bg-warning rounded-pill"
                                          : item.priority == "Medium"
                                          ? "font-size-12 badge-soft-primary badge bg-success rounded-pill"
                                          : item.priority == "Low"
                                          ? "font-size-12 badge-soft-success badge bg-primary rounded-pill"
                                          : "font-size-12 badge-soft-danger badge bg-secondary rounded-pill"
                                      }
                                    >
                                      {item.priority}
                                    </p>
                                  </div>

                                  <div className="col-8  p-0 m-0 mt-n2">
                                    <div className="avatar-group">
                                      {item.assignee?.map((team, secondkey) => (
                                        <React.Fragment
                                          key={"_teamaa__" + secondkey}
                                        >
                                          <div className="avatar-group-item ml-auto ms-auto">
                                            <span>
                                              {" "}
                                              {team.uid.toUpperCase()}
                                            </span>

                                            <img
                                              src={`${base_url}/user/fetch_profile_image/${team.image}`}
                                              className="rounded-circle avatar-xs"
                                              alt=""
                                            />
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-3 ">
                    <div className="card">
                      <h4 className="card m-2 p-2">In Progress </h4>

                      <div
                        className="card-body"
                        style={{
                          minHeight: "5em",
                          maxHeight: "58em",
                          overflow: "auto",
                        }}
                      >
                        {InProgress.length > 0 &&
                          InProgress.map((item, key) => (
                            <div className="card" key={key}>
                              <div className="card-body">
                                <div className="row ">
                                  <Icon
                                    type={item.type}
                                    typeValue={"type"}
                                    className="col-1 d-block"
                                  ></Icon>{" "}
                                  <span
                                    style={{ fontWeight: "600" }}
                                    className="col-9 d-block"
                                  >
                                    <a
                                      href={`/ticket-overview/${params.name}/${item.ticket_id}`}
                                    >
                                      {" "}
                                      {item.ticket_id}
                                    </a>
                                  </span>
                                  <UncontrolledDropdown className="col-1">
                                    <DropdownToggle
                                      href="#"
                                      className="arrow-none"
                                      tag="i"
                                    >
                                      <i
                                        className="mdi mdi-dots-vertical m-0 text-muted h5"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <Link>
                                        <DropdownItem
                                          onClick={() => {
                                            changeStatusHandler(
                                              "Open",
                                              item.ticket_id
                                            );
                                          }}
                                        >
                                          Open
                                        </DropdownItem>
                                      </Link>
                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "Under Review",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        Under Review
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "Completed",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        Completed
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>

                                <div className="row mt-2">
                                  <h6 className="col-12">{item.title} </h6>
                                </div>
                                <Col xl="12 row mb-1 mt-2">
                                  <div className="d-flex  col-4 flex-wrap">
                                    <p
                                      className={
                                        item.priority == "High"
                                          ? "font-size-12 badge-soft-warning badge bg-warning rounded-pill"
                                          : item.priority == "Medium"
                                          ? "font-size-12 badge-soft-primary badge bg-success rounded-pill"
                                          : item.priority == "Low"
                                          ? "font-size-12 badge-soft-success badge bg-primary rounded-pill"
                                          : "font-size-12 badge-soft-danger badge bg-secondary rounded-pill"
                                      }
                                    >
                                      {item.priority}
                                    </p>
                                  </div>

                                  <div className="col-8  p-0 m-0 mt-n2">
                                    <div className="avatar-group">
                                      {item.assignee?.map((team, secondkey) => (
                                        <React.Fragment
                                          key={"_teamaa__" + secondkey}
                                        >
                                          <div className="avatar-group-item ml-auto ms-auto">
                                            <span>
                                              {" "}
                                              {team.uid.toUpperCase()}
                                            </span>

                                            <img
                                              src={`${base_url}/user/fetch_profile_image/${team.image}`}
                                              className="rounded-circle avatar-xs"
                                              alt=""
                                            />
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-3 ">
                    <div className="card">
                      <h4 className="card m-2 p-2">Under Review</h4>

                      <div
                        className="card-body"
                        style={{
                          minHeight: "5em",
                          maxHeight: "58em",
                          overflow: "auto",
                        }}
                      >
                        {UnderReview.length > 0 &&
                          UnderReview.map((item, key) => (
                            <div className="card" key={key}>
                              <div className="card-body">
                                <div className="row ">
                                  <Icon
                                    type={item.type}
                                    typeValue={"type"}
                                    className="col-1 d-block"
                                  ></Icon>{" "}
                                  <span
                                    style={{ fontWeight: "600" }}
                                    className="col-9 d-block"
                                  >
                                    <a
                                      href={`/ticket-overview/${params.name}/${item.ticket_id}`}
                                    >
                                      {" "}
                                      {item.ticket_id}
                                    </a>
                                  </span>
                                  <UncontrolledDropdown className="col-1">
                                    <DropdownToggle
                                      href="#"
                                      className="arrow-none"
                                      tag="i"
                                    >
                                      <i
                                        className="mdi mdi-dots-vertical m-0 text-muted h5"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "Open",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        Open
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "In Progress",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        In Progress
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "Completed",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        Completed
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>

                                <div className="row mt-2">
                                  <h6 className="col-12">{item.title} </h6>
                                </div>
                                <Col xl="12 row mb-1 mt-2">
                                  <div className="d-flex  col-4 flex-wrap">
                                    <p
                                      className={
                                        item.priority == "High"
                                          ? "font-size-12 badge-soft-warning badge bg-warning rounded-pill"
                                          : item.priority == "Medium"
                                          ? "font-size-12 badge-soft-primary badge bg-success rounded-pill"
                                          : item.priority == "Low"
                                          ? "font-size-12 badge-soft-success badge bg-primary rounded-pill"
                                          : "font-size-12 badge-soft-danger badge bg-secondary rounded-pill"
                                      }
                                    >
                                      {item.priority}
                                    </p>
                                  </div>

                                  <div className="col-8  p-0 m-0 mt-n2">
                                    <div className="avatar-group">
                                      {item.assignee?.map((team, secondkey) => (
                                        <React.Fragment
                                          key={"_teamaa__" + secondkey}
                                        >
                                          <div className="avatar-group-item ml-auto ms-auto">
                                            <span>
                                              {" "}
                                              {team.uid.toUpperCase()}
                                            </span>

                                            <img
                                              src={`${base_url}/user/fetch_profile_image/${team.image}`}
                                              className="rounded-circle avatar-xs"
                                              alt=""
                                            />
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-3 ">
                    <div className="card">
                      <h4 className="card m-2 p-2">Completed</h4>

                      <div
                        className="card-body"
                        style={{
                          minHeight: "5em",
                          maxHeight: "58em",
                          overflow: "auto",
                        }}
                      >
                        {Completed.length > 0 &&
                          Completed.map((item, key) => (
                            <div className="card" key={key}>
                              <div className="card-body">
                                <div className="row ">
                                  <Icon
                                    type={item.type}
                                    typeValue={"type"}
                                    className="col-1 d-block"
                                  ></Icon>{" "}
                                  <span
                                    style={{ fontWeight: "600" }}
                                    className="col-9 d-block"
                                  >
                                    <a
                                      href={`/ticket-overview/${params.name}/${item.ticket_id}`}
                                    >
                                      {" "}
                                      {item.ticket_id}
                                    </a>
                                  </span>
                                  <UncontrolledDropdown className="col-1">
                                    <DropdownToggle
                                      href="#"
                                      className="arrow-none"
                                      tag="i"
                                    >
                                      <i
                                        className="mdi mdi-dots-vertical m-0 text-muted h5"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <Link>
                                        <DropdownItem
                                          onClick={() => {
                                            changeStatusHandler(
                                              "Open",
                                              item.ticket_id
                                            );
                                          }}
                                        >
                                          Open
                                        </DropdownItem>
                                      </Link>

                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "In Progress",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        In Progress
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          changeStatusHandler(
                                            "Under Review",
                                            item.ticket_id
                                          );
                                        }}
                                      >
                                        Under Review
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>

                                <div className="row mt-2">
                                  <h6 className="col-11">{item.title} </h6>
                                </div>
                                <Col xl="12 row mb-1 mt-2">
                                  <div className="d-flex  col-4 flex-wrap">
                                    <p
                                      className={
                                        item.priority == "High"
                                          ? "font-size-12 badge-soft-warning badge bg-warning rounded-pill"
                                          : item.priority == "Medium"
                                          ? "font-size-12 badge-soft-primary badge bg-success rounded-pill"
                                          : item.priority == "Low"
                                          ? "font-size-12 badge-soft-success badge bg-primary rounded-pill"
                                          : "font-size-12 badge-soft-danger badge bg-secondary rounded-pill"
                                      }
                                    >
                                      {item.priority}
                                    </p>
                                  </div>

                                  <div className="col-8  p-0 m-0 mt-n2">
                                    <div className="avatar-group">
                                      {item.assignee?.map((team, secondkey) => (
                                        <React.Fragment
                                          key={"_teamaa__" + secondkey}
                                        >
                                          <div className="avatar-group-item ml-auto ms-auto">
                                            <span>
                                              {" "}
                                              {team.uid.toUpperCase()}
                                            </span>

                                            <img
                                              src={`${base_url}/user/fetch_profile_image/${team.image}`}
                                              className="rounded-circle avatar-xs"
                                              alt=""
                                            />
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>

        {modal && (
          <Modal isOpen={modal} size="xl">
            <Row>
              <Col xs={11}>
                <h4 className="m-3">Add Task</h4>
              </Col>
              <Col>
                <i
                  className="fas m-3 fa-times"
                  style={{ cursor: "pointer" }}
                  onClick={closeModalHandler}
                ></i>
              </Col>
            </Row>
            <hr></hr>

            <ModalBody>
              <AddTicketModal
                projectId={params.id}
                manager={managerOptions}
                members={teamMembers}
                closeAddModal={closeAddModalHandler}
              />
            </ModalBody>
          </Modal>
        )}

        <Modal isOpen={editModal} size="xl">
          <Row>
            <Col xs={11}>
              <h4 className="m-3">Edit Task</h4>
            </Col>
            <Col>
              <i
                className="fas m-3 fa-times"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditModal(false);
                }}
              ></i>
            </Col>
          </Row>
          <hr></hr>

          <ModalBody>
            <Form onSubmit={onEditTicket}>
              <FormGroup className="mb-4" row>
                <Label className="col-form-label col-lg-2">Title</Label>
                <Col lg="10">
                  <Input
                    name="title"
                    value={title}
                    onChange={onTitleValue}
                    className="form-control"
                    placeholder="Enter Project Title..."
                  />
                </Col>
              </FormGroup>

              <FormGroup className="mb-4" row>
                <div className="col-md-5 offset-2">
                  <label className="col-md-1 col-form-label">Type</label>

                  <select
                    className="form-control"
                    onChange={onTypeValue}
                    value={type}
                    name="type"
                  >
                    <option>Bug</option>
                    <option>Task</option>
                    <option>Feature</option>
                    <option>Epic</option>
                  </select>
                </div>

                <div className="col-md-5">
                  <label className="col-md-1 col-form-label">Priority</label>

                  <select
                    className="form-control"
                    onChange={onPriorityValue}
                    value={priority}
                    name="priority"
                  >
                    <option>Low</option>
                    <option>Medium </option>
                    <option>High</option>
                    <option>Highest</option>
                  </select>
                </div>
              </FormGroup>

              <FormGroup className="mb-4" row>
                <Label className="col-form-label col-lg-2">Description</Label>
                <Col lg="10">
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onReady={editor => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="mb-4" row>
                <div className="col-md-5 offset-2">
                  <label className="col-md-1 col-form-label">Reporter</label>

                  <Select
                    defaultValue={reporter}
                    onChange={onSetReporter}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={managerOptions}
                    isMulti
                  />
                </div>

                <div className="col-md-5">
                  <label className="col-md-1 col-form-label">Assignee</label>

                  <Select
                    defaultValue={assignee}
                    onChange={onsetAssignee}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={teamMembers}
                    isMulti
                  />
                </div>
              </FormGroup>

              <FormGroup className="mb-4 d-flex justify-content-between" row>
                <div className="col-md-3 offset-2">
                  <label className="col-md-1 col-form-label">Status</label>
                  <select
                    className="form-control"
                    onChange={onStatusValue}
                    value={ticket_status}
                    name="status"
                  >
                    <option>Open</option>
                    <option>In Progress</option>
              
                  </select>
                </div>

                <div className="col-md-3 ">
                  <label className="col-md-1 col-form-label">skills</label>

                  <Input
                    name="skills"
                    value={skills}
                    onChange={onSkillsValue}
                    className="form-control"
                    placeholder="Enter skills..."
                  />
                </div>

                <div className="col-md-3 ">
                  <label className="col-form-label">Estimate time</label>
                  <Input
                    name="title"
                    type="text"
                    onChange={onEstimateTime}
                    className="form-control"
                    placeholder="Enter Time..."
                    value={time}
                  />
                </div>
              </FormGroup>

              <Button type="submit" color="primary">
                Submit
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ShowTicketTable;
