/* PROJECTS */
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL"

/* PROJECTS DETAIL*/
export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL"
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS"
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL"

export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL"

export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL"

export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"
export const FETCH_USER = "FETCH_USER"
export const FETCH_CUSTOMER = "FETCH_CUSTOMER"
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS"




export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"
export const ADD_ATTACHMENT = "ADD_ATTACHMENT"
export const ADD_ATTACHMENT_SUCCESS = "ADD_ATTACHMENT_SUCCESS"

export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT"
export const ADD_TICKET = "ADD_TICKET"
export const ADD_TICKET_SUCCESS = "ADD_TICKET_SUCCESS"
export const FETCH_TICKETS = "FETCH_TICKETS"
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS"
export const DELETE_TICKET = "DELETE_TICKET"
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS"
export const EDIT_TICKET = "EDIT_TICKET"
export const GET_TICKET_DETAIL = "GET_TICKET_DETAIL"
export const GET_TICKET_DETAIL_SUCCESS = "GET_TICKET_DETAIL_SUCCESS"
export const DELETE_ATTACHMENT_TICKET = "DELETE_ATTACHMENT_TICKET"
export const ADD_ATTACHMENT_TICKET = "ADD_ATTACHMENT_TICKET"
export const ADD_COMMENT = "ADD_COMMENT"
export const FETCH_COMMENT = "FETCH_COMMENT"
export const GET_TICKET_COMMENT_SUCCESS = "GET_TICKET_COMMENT_SUCCESS"
export const GET_TICKET_COMMENT_FAIL = "GET_TICKET_COMMENT_FAIL"

export const DELETE_COMMENT_BY_INDEX = "DELETE_COMMENT_BY_INDEX"
export const EDIT_COMMENT_HANDLER = "EDIT_COMMENT_HANDLER"
export const DELINK_TICKET = "DELINK_TICKET"
export const GET_ICONS = "GET_ICONS"
export const GET_FETCH_ICON_SUCCESS = "GET_FETCH_ICON_SUCCESS"
export const ADD_NEW_LINK = "ADD_NEW_LINK"
export const FETCH_SUB_TICKETS = "FETCH_SUB_TICKETS"
export const FETCH_SUB_TICKETS_SUCCESS = "FETCH_SUB_TICKETS_SUCCESS"
export const FETCH_NEW_DETAILS = "FETCH_NEW_DETAILS"
export const UPDATE_STATUS = "UPDATE_STATUS"
export const GET_GRAPH_DATA_DAILY = "GET_GRAPH_DATA_DAILY"
export const GET_GRAPH_DATA_DAILY_SUCCESS = "GET_GRAPH_DATA_DAILY_SUCCESS"
export const GET_GRAPH_DATA_WEEKLY = "GET_GRAPH_DATA_WEEKLY"
export const GET_GRAPH_DATA_WEEKLY_SUCCESS = "GET_GRAPH_DATA_WEEKLY_SUCCESS"
export const GET_TICKET_OWNER_GRAPH = "GET_TICKET_OWNER_GRAPH"
export const GET_TICKET_OWNER_GRAPH_SUCCESS = "GET_TICKET_OWNER_GRAPH_SUCCESS"
export const UPDATE_STATUS_KANBAN_BOARD = "UPDATE_STATUS_KANBAN_BOARD"
export const ADD_EVENT = "ADD_EVENT"
export const FETCH_EVENTS = "FETCH_EVENTS"
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS"
export const EDIT_EVENT_SUBMIT = "EDIT_EVENT_SUBMIT"
export const DELETE_ADDED_EVENT = "DELETE_ADDED_EVENT"
export const FETCH_SINGLE_EVENT = "FETCH_SINGLE_EVENT"
export const FETCH_SINGLE_EVENT_SUCCESS = "FETCH_SINGLE_EVENT_SUCCESS"
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const FETCH_USER_INFORMATION = "FETCH_USER_INFORMATION"






















































































































