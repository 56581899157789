import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle,Button } from "reactstrap";
import { Link, useParams, withRouter } from "react-router-dom";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import Icon from "pages/Projects/ProjectOverview/icon";
const DatatableTables = props => {

  const { items, columns, addButtonHandler,showAddButton,addButtonLabel } = props;
  const state = {
    page: 1,
    sizePerPage: 10,
    productData: items,
  };
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const pageOptions = {
    sizePerPage: 10,
    totalSize: items.length, // replace later with size(customers),
    custom: true,
  };
  // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  // };
  const { SearchBar } = Search;
  //meta title
  return (
    <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={columns}
        data={state?.productData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={state?.productData}
            search
            bootstrap4
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col md="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                  <Col md="4"></Col>
                  {showAddButton &&
                    <Col md="4">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded mb-2 me-2"
                        onClick={addButtonHandler}
                        style={{ float: "right" }}
                      >
                        <i className="mdi mdi-plus me-1" /> {addButtonLabel}
                      </Button>
                    </Col>
                   }
                </Row>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">

                  
                      <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        data={items}
                        columns={columns}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"table-dark"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      >
                       </BootstrapTable>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
  );
};
export default DatatableTables;