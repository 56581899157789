import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Col, Container, Row , Card, CardBody,CardTitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  getProjectDetail,
  getGraphDataDaily,
  getGraphDataWeekly,
  getTicketOwnerGraph
} from "store/projects/actions";
import ProjectDetail from "./projectDetail";
import TeamMembers from "./teamMembers";
import OverviewChart from "./overviewChart";
import { options, series } from "common/data/projects";
import AttachedFiles from "./attachedFiles";
import Comments from "./comments";
import DountChart from "pages/AllCharts/chartjs/dountchart";
import CustomDonutChart from "./CustomDonutChart";
import CustomSplineChart from "./CustomSplineChart";
import Apaexlinecolumn from "pages/AllCharts/apex/apaexlinecolumn";
import CustomColumnChart from "./CustomColumnChart";



const ProjectsOverview = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectDetail(params.id));

    dispatch(getGraphDataDaily(params.id));

    dispatch(getGraphDataWeekly(params.id));

    dispatch(getTicketOwnerGraph(params.id));

  }, []);

  const projectDetail = useSelector(state => state.projects.projectDetail);
  const graphDetailDaily = useSelector(
    state => state.projects.graphDetailsDaily
  );
  const graphDetailsWeekly = useSelector(
    state => state.projects.graphDetailsWeekly
  );


console.log('sub',graphDetailsWeekly)
  const graphDetailsOwner = useSelector(
    state => state.projects.graphDetailsOwner
  );


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="row mb-2">
              <div className="col-12 d-flex justify-content-end">
                <span>
                  <span className="text-muted">Projects</span>
                  <Link to={'/view-tickets/'+projectDetail.title+"/"+projectDetail._id} >/View Tickets</Link>
                </span>
              </div>
            </div>

          {!isEmpty(projectDetail) && (
            <>
              <Row>
                <Col lg="8">
                  <ProjectDetail
                    variable={"project"}
                    project={projectDetail}
                    graphDetailDailyData={graphDetailDaily}
                    graphDetailsWeeklyData={graphDetailsWeekly}
                  />
                </Col>


                <Col lg="4" className="align-self-start">
                  <AttachedFiles files={projectDetail.attachments} />
                </Col>

              </Row>

              <Row>
            

  

  
       { !isEmpty(graphDetailDaily.priority) &&         <Col lg={4}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Priority</CardTitle>
               

                      <CustomDonutChart priority={graphDetailDaily.priority} value={'Priority'} />
                    </CardBody>
                  </Card>
                </Col>}

            { !isEmpty(graphDetailDaily.status) &&     <Col lg={4}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Status</CardTitle>
                   

                      <CustomDonutChart  status={graphDetailDaily.status} value={'Status'}  />
                    </CardBody>
                  </Card>
                </Col>}

              {!isEmpty(graphDetailDaily.type) &&    <Col lg={4}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Type</CardTitle>
              

                      <CustomDonutChart  type={graphDetailDaily.type} value={'Type'}/> 
                   </CardBody>
                  </Card>
                </Col>
}
            { graphDetailsWeekly ?   <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Weekly Report</CardTitle>
                 {  !isEmpty(graphDetailsWeekly)  &&  <CustomSplineChart  graphDetail={graphDetailsWeekly}/> }
                   </CardBody>
                  </Card>
                </Col>:''}
                

             {graphDetailsOwner ?   <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"> Column Chart </CardTitle>
                   {!isEmpty(graphDetailsOwner)   && <CustomColumnChart  graphDetail={graphDetailsOwner} />}
                  </CardBody>
                </Card>
              </Col>:''}
              

              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProjectsOverview;

// ProjectsOverview.propTypes = {
//   projectDetail: PropTypes.any,
//   match: PropTypes.object,
//   onGetProjectDetail: PropTypes.func,
// };

// const mapStateToProps = ({ projects }) => ({
//   projectDetail: projects.projectDetail,
// });

// const mapDispatchToProps = dispatch => ({
//   onGetProjectDetail: id => dispatch(getProjectDetail(id)),
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(ProjectsOverview));
