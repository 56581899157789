import {
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_PROJECT_SUCCESS,
  ADD_TICKET_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  FETCH_USER_SUCCESS,
  ADD_ATTACHMENT_SUCCESS,
  GET_TICKETS_SUCCESS,
  DELETE_TICKET_SUCCESS,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_COMMENT_SUCCESS,
  GET_FETCH_ICON_SUCCESS,
  FETCH_SUB_TICKETS_SUCCESS,
  GET_GRAPH_DATA_DAILY_SUCCESS,
  GET_GRAPH_DATA_WEEKLY_SUCCESS,
  GET_TICKET_OWNER_GRAPH_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FETCH_SINGLE_EVENT_SUCCESS,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_USER_DETAIL_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  GET_TICKET_COMMENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  projects: [],
  projectDetail: {},
  userDetail:{},
  graphDetailsDaily: {},
  graphDetailsOwner: {},
  graphDetailsWeekly: {},
  ticketDetail: {},
  users: [],
  customer:[],
  tickets: [],
  error: {},
  ticketComments: [],
  icon: [],
  subTasks: [],
  events: [],
  singleEvent: [],
  getDashboardData:{}
};

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

      case FETCH_CUSTOMER_SUCCESS:
        return {
          ...state,
          customer: action.payload,
        };
  


    case GET_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
      };

    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
      };

    case FETCH_SINGLE_EVENT_SUCCESS:
      return {
        ...state,
        singleEvent: action.payload,
      };

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_FETCH_ICON_SUCCESS:
      return {
        ...state,
        icon: action.payload,
      };

    case FETCH_SUB_TICKETS_SUCCESS:
      return {
        ...state,
        subTasks: action.res,
      };

    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetail: action.payload,
      };


      case GET_USER_DETAIL_SUCCESS:
        return {
          ...state,
          userDetail: action.payload,
        };

    case GET_GRAPH_DATA_DAILY_SUCCESS:
      return {
        ...state,
        graphDetailsDaily: action.payload,
      };

      case GET_DASHBOARD_DATA_SUCCESS:
        return {
          ...state,
          getDashboardData: action.payload,
        };

    case GET_GRAPH_DATA_WEEKLY_SUCCESS:
      return {
        ...state,
        graphDetailsWeekly: action.payload,
      };

    case GET_TICKET_OWNER_GRAPH_SUCCESS:
      return {
        ...state,
        graphDetailsOwner: action.payload,
      };

    case GET_TICKET_DETAIL_SUCCESS:
      return {
        ...state,
        ticketDetail: action.payload,
      };

    case GET_TICKET_COMMENT_SUCCESS:
      return {
        ...state,
        ticketComments: action.payload,
      };

      case GET_TICKET_COMMENT_FAIL:
        return {
          ...state,
          ticketComments: [],
        };

    case GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };

    case ADD_TICKET_SUCCESS:
      return {
        ...state,
        tickets: [...state.tickets, action.payload],
      };

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(project =>
          project.id.toString() === action.payload.id.toString()
            ? { project, ...action.payload }
            : project
        ),
      };

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(
          project => project._id !== action.payload
        ),
      };

    case DELETE_TICKET_SUCCESS:
      return {
        ...state,
        tickets: state.tickets.filter(
          ticket => ticket.ticket_id !== action.payload
        ),
      };

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default projects;
